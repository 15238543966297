import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { StorageHelper } from 'src/app/shared/helper/helper';
@Injectable()
export class AuthService {
  result: boolean;
  constructor(private router: Router, private ls: StorageHelper) { }
  public isAuthenticated(): boolean {
    const token = this.ls.secureStorage('get', 'token');
    console.log('token', token);
    if (token) {
      // const helper = new JwtHelperService();
      // helper.decodeToken(token)
      // !helper.isTokenExpired(token);
      try {
        if (token) {
          return true;
        } else {
          this.router.navigate(['/home']);
        }
      } catch {
        this.router.navigate(['/home']);
      }
    }
  }
}
