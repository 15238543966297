import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'popup',
  templateUrl: 'popup.component.html',
  styleUrls: ['popup.component.scss']
})
export class PopupComponent {
  title: string = "Are you sure?"
  message: string = ""
  confirmButtonText = "Yes"
  cancelButtonText = "";
  enableBtn = false;
  enableIcon = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupComponent>) {
    if (data) {
      console.log('data', data);
      this.message = data.message;
      this.title = data.title || this.title;
      this.enableBtn = data.enableBtn;
      this.enableIcon = data.enableIcon;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(res): void {
    this.dialogRef.close(res);
  }
}

