<nav class="navbar navbar-expand-lg header-box navbar-light bg-light fixed-top">
  <a class="navbar-brand ml-lg-3">
    <img src="./assets/images/logo.png"  routerLink="/board" alt="logo" id="logo" width="120px" />
  </a>
  <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="my-nav" class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mx-3">
        <a class="nav-link btn header-btn" id="home" routerLink="/board">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn header-btn" id="signOut" (click)="signOut()">Sign out</a>
      </li>
    </ul>
  </div>
</nav>
