export class HomeHelper {
  constructor() { }

  mapResetpasswordformDataToService(formdata){
    if (formdata){
      const payload = {
        email: formdata['rpEmail'],
        password: formdata['rpNewPassword'],
        cpassword: formdata['rpConfirmPassword'],
        code: formdata['rpverificationcode']
      }
      return payload;
    }
  }

  mapForgotpasswordformDataToService(formdata){
    if (formdata){
      const payload = {
        email: formdata['fpEmail'],
        mobile: formdata['fpMobileNumber']
      }
      return payload;
    }
  }

  mapLoginformDataToService(formdata){
    if (formdata){
      const payload = {
        email: formdata['email'],
        password: formdata['password']
      }
      return payload;
    }
  }
}
