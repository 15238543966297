<div [class]="enableModal ? 'modal show' :''" tabindex="-1" role="dialog">
  <div [class]="enableModal ? 'modal-dialog modal-lg' : ''" role="document">
    <div [class]="enableModal ?'modal-content' :''">
      <form [formGroup]="onbardForm" autocomplete="off">
        <ng-container>
          <div class="login-container" formGroupName="verifyEmail" id="login-form">
            <div class="col-lg-12 login-card px-4 py-4">
              <div class="col-lg-12 py-3 forgot-card px-1">
                <h5 class="text-primary pb-4 ml-2">Email verification</h5>
                <p class="instruction px-2">Please enter the 4 digit code send to your email</p>
                <div class="col-lg-12 px-2 pb-lg-3">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <label for="code">Verification code</label>
                        <input (keydown.enter)="verifyEmail()" type="tel" numbersOnly class="form-control" id="code"
                          maxlength="4" name="code" formControlName="code">
                        <p
                          class="error-blk {{(oFormVerifyEmail?.code?.errors?.required && oFormVerifyEmail?.code?.touched) ? 'mb-0':'mb-4'}}">
                          <span class="error"
                            *ngIf="(oFormVerifyEmail?.code?.errors?.required && oFormVerifyEmail?.code?.touched)">Cannot
                            be
                            blank.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 terms-blk">
                  <div class="row">
                    <div class="col-lg-6 offset-lg-6 px-lg-2">
                      <div class="row">
                        <div class="col-lg-12 text-lg-right text-center pb-2 pt-lg-0 pt-2  pb-lg-0">
                          <button type="button" id="signInBtn" *ngIf="!loader" class="btn btn-more"
                            (click)="verifyEmail()">Submit</button>
                          <button type="button" id="signInBtn" class="btn btn-more" *ngIf="loader">
                            <div class="loader"></div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>