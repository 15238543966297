<div class="card">
  <ul class="timer-blk">
    <li class="box timer">
      <p class="mb-0">Time Taken: {{_counter}} Sec</p>
    </li>
    <li class="box timer-recommended">
      <p class="mb-0">Time Recommended: {{timeRecommended}} Sec</p>
    </li>
    <li class="box marks-scored">
      <p class="mb-0">Marks Scored: {{score}}</p>
    </li>
  </ul>
</div>

