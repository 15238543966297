import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JppService } from 'src/app/providers/jpp.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public service: JppService, public router: Router) { }

  ngOnInit(): void {
  }

  signOut() {
    this.router.navigate(['/home'], {queryParams:{'logout':true}});
  }
}
