export const data = [
  {
    icon: './assets/icons/writing.svg',
    title: 'Writing',
    color: '',
    id: 0,
  },
  {
    icon: './assets/icons/reading.svg',
    title: 'Reading',
    color: '',
    id: 1,
  }
];

export const writingCategory = [
  {
    title: 'Essay',
    subtitle: '3 Questions',
    btn: 'Start',
    id: 'w0',
    type: 'essay',
    index: 0
  },
  {
    title: 'Summarise written text',
    subtitle: '2 Questions',
    btn: 'Start',
    id: 'w1',
    type: 'summarise',
    index: 1
  },
]
export const readingCategory = [
  {
    title: 'Re-order paragraphs',
    subtitle: '3 Questions',
    btn: 'Start',
    id: 'r0',
    index: 0,
    disable: false,
    type: 're_order_paragraph'
  },
  {
    title: 'Reading & Writing: Fill in the blanks',
    subtitle: '2 Questions',
    btn: 'Start',
    id: 'r2',
    index: 2,
    disable: false,
    type: 'reading_and_writing'
  },
  {
    title: 'Reading: Fill in the blanks',
    subtitle: '3 Questions',
    btn: 'Start',
    id: 'r4',
    index: 4,
    disable: false,
    type: 'drag_and_drop'
  },
  {
    title: 'Multiple choice, choose multiple answer',
    subtitle: '3 Questions',
    btn: 'Start',
    id: 'r3',
    index: 3,
    disable: false,
    type: 'mcq_multi_ans'
  },
  {
    title: 'Multiple choice, choose single answer',
    subtitle: '3 Questions',
    btn: 'Start',
    id: 'r1',
    index: 1,
    disable: false,
    type: 'mcq_single_ans'
  }
]
export const introContent = {
  reading: [
    {
      title: 'Re-order paragraphs (Reading)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/reading-p-intro.webp',
      id: 'r0',
      btn: 'Start',
      index: 0
    },
    {
      title: 'Multiple choice, choose single answer (Reading)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/multiple-choice-single-ans-r.jpg',
      id: 'r2',
      btn: 'Start',
      index: 2
    }, {
      title: 'Fill in the blanks (Reading and Writing)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/reading-writing-fill.webp',
      id: 'r1',
      btn: 'Start',
      index: 1
    },
    {
      title: 'Multiple choice, choose multiple answer (Reading)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/multiple-choice-multi-ans-r.jpg',
      id: 'r3',
      btn: 'Start',
      index: 3
    },
    {
      title: 'Question: Fill in the blanks (Reading)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/fill-in-the-blank.jpg',
      id: 'r4',
      btn: 'Start',
      index: 4
    }
  ],
  writing: [
    {
      title: 'Essay (Writing)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/essay-writing.jpg',
      id: 'w0',
      btn: 'Start',
      index: 0
    },
    {
      title: 'Summarise written text (Writing)',
      subtitle: 'Please click Start when you are ready to begin the activity.',
      image: './assets/images/summarise-essay.jpg',
      id: 'w1',
      btn: 'Start',
      index: 1
    }
  ]
}

export const readingModuleReorderSampleData = {
  title: 'Sample Question - Re-order paragraphs (Reading)',
  subtitle: 'The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.',
  btn: 'CHECK ANSWER',
  score: 0,
  timer: 0,
  questions: [
    {
      source: [
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'd'
        },
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'a'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'b'
        },
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'c'
        }
      ],
      result: ['ab', 'bc', 'cd'],
      target: ['', '', '', ''],
      answer: [
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'a'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'b'
        },
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'c'
        },
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'd'
        }
      ],
      timer: 0,
      score: 0,
      recommendtime: 60
    },
    {
      source: [
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'b'
        },
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'd'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'c'
        },
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'a'
        },
        {
          data: 'E.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'e'
        }
      ],
      result: ['ab', 'bc', 'cd', 'de'],
      target: ['', '', '', '', ''],
      answer: [
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'a'
        },
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'b'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'c'
        },
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'd'
        },
        {
          data: 'E.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'e'
        }
      ],
      timer: 0,
      score: 0,
      recommendtime: 70
    }
  ]
}

export const readingModuleReorderSampleDataNew = {
  title: 'Sample Question - Re-order paragraphs (Reading)',
  subtitle: 'The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.',
  btn: 'CHECK ANSWER',
  score: 0,
  timer: 0,
  questions: [
    {
      answer: [
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'a'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'b'
        },
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'c'
        },
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'd'
        }
      ],
      timer: 0,
      score: 0,
      recommendtime: 60
    },
    {
      answer: [
        {
          data: 'B.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q ',
          id: 'a'
        },
        {
          data: 'A.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a',
          id: 'b'
        },
        {
          data: 'C.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in ',
          id: 'c'
        },
        {
          data: 'D.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'd'
        },
        {
          data: 'E.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sus pendisse sit amet dignissim purus. Nulla vehicula in leo quis vol utpat. Donec mauris nulla, suscipit a quam vel, it a q',
          id: 'e'
        }
      ],
      timer: 0,
      score: 0,
      recommendtime: 70
    }
  ]
}

export const readingModuleFillInBlankSampleData = {
  title: 'Sample question - Fill in the blanks (Reading and Writing)',
  subtitle: 'Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.',
  btn: 'CHECK ANSWER',
  questions: [
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `The introduction of security footage in courtrooms as evidence is increasingly common. With the number of closed-circuit television (CCTV) cameras rising, the likelihood of images deemed relevant for criminal proceedings being recorded also increases. However, while CCTV footage may arguably have assisted in achieving {{response}} in some high-profile cases, can it assist in the overall reduction of violence against women?
      A well-known example in Australia of CCTV helping solve a crime is the footage pulled from a shop on Sydney Road the night Jill Meagher was raped and murdered by Adrian Bayley. This footage was mentioned in his sentencing in 2013. In the same year, Simon Gittany was found
                                                of the murder of his partner Lisa Harnum. CCTV footage – taken from his own security cameras – was again
                                         as key evidence. Beyond the courtroom, news media reports of crime are saturated with the use of CCTV footage. In both contexts, it is often seen to be {{response}}
      an authoritative and objective witness that can tell us "what really happened".
      While used in a range of offences, its inclusion in instances of extreme (and public) violence against women can mean certain images receive significant and sustained media
                                            , and may remain online indefinitely. The strength of CCTV in our public consciousness is such that questions of privacy are often dismissed as inconsequential. {{response}} CCTV installed in the homes of family violence victims has even been considered.`,
      names: ['Option1', 'Option2', 'Option3'],
      options: [
        [
          {
            name: 'sentiment',
            value: 'sentiment'
          },
          {
            name: 'distrust',
            value: 'distrust'
          },
          {
            name: 'principels',
            value: 'principels'
          },
          {
            name: 'convinctions',
            value: 'convinctions'
          }
        ],
        [
          {
            name: 'sentiments',
            value: 'sentiments'
          },
          {
            name: 'distrust',
            value: 'distrust'
          },
          {
            name: 'principels',
            value: 'principels'
          },
          {
            name: 'convinctions',
            value: 'convinctions'
          }
        ],
        [
          {
            name: 'content',
            value: 'content'
          },
          {
            name: 'distrust',
            value: 'distrust'
          },
          {
            name: 'principels',
            value: 'principels'
          },
          {
            name: 'convinctions',
            value: 'convinctions'
          }
        ]
      ],
      answer: [
        'convinctions', 'principels', 'convinctions'
      ]
    },
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `The introduction of security footage in courtrooms as evidence is increasingly common. With the number of closed-circuit television (CCTV) cameras rising, the likelihood of images deemed relevant for criminal proceedings being recorded also increases. However, while CCTV footage may arguably have assisted in achieving {{response}} in some high-profile cases, can it assist in the overall reduction of violence against women?
      A well-known example in Australia of CCTV helping solve a crime is the footage pulled from a shop on Sydney Road the night Jill Meagher was raped and murdered by Adrian Bayley. This footage was mentioned in his sentencing in 2013. In the same year, Simon Gittany was found
                                                of the murder of his partner Lisa Harnum. CCTV footage – taken from his own security cameras – was again
                                         as key evidence. Beyond the courtroom, news media reports of crime are saturated with the use of CCTV footage. In both contexts {{response}} it is often seen to be
      an authoritative and objective witness that can tell us "what really happened".
      While used in a range of offences, its inclusion in instances of extreme (and public) violence against women can mean certain images receive significant and sustained media
                                            , and may remain online indefinitely. The strength of CCTV in our public consciousness is such that questions of privacy are often dismissed as inconsequential. CCTV installed in the homes of family violence victims has even been considered.`,
      names: ['Option1', 'Option2'],
      options: [
        [
          {
            name: 'increasingly',
            value: 'increasingly'
          },
          {
            name: 'distrust',
            value: 'distrust'
          },
          {
            name: 'principels',
            value: 'principels'
          },
          {
            name: 'convinctions',
            value: 'convinctions'
          }
        ],
        [
          {
            name: 'sentiments',
            value: 'sentiments'
          },
          {
            name: 'distrust',
            value: 'distrust'
          },
          {
            name: 'principels',
            value: 'principels'
          },
          {
            name: 'convinctions',
            value: 'convinctions'
          }
        ]
      ],
      answer: [
        'convinctions', 'principels'
      ]
    }
  ]
}

export const mcSingleAnsSampleData = {
  title: 'Sample Question - Multiple choice, choose single answer (Reading)',
  subtitle: 'Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.',
  btn: 'CHECK ANSWER',
  questions: [
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `Modern liberal ideals of ethnic tolerance and diversity are taking hold in many places, but many ethnic-based societies exist where such ideals have yet to gain wide acceptance. Usually, this is the case because one ethnicity is dominant or in the much greater majority. In Japan, the Korean minority is stigmatised and excluded from positions of power in the society. Korean children, if they wish to be accepted by their peers, must attend Japanese schools and make Japanese their first language. They are made to feel ashamed of their Korean ethnic identity and culture.
      A similar movement on the part of conservatives in the US has sought to make English mandatory for foreign-born citizens. Such moves have the effect of making children feel stigmatised because of their ethnicity, and they turn that hatred from outside on themselves. Ethnic minorities, as a result, are usually characterised by higher levels of pathology, both physical and emotional, than the dominant population.`,
      question: 'In what kind of societies have ethnic tolerance and diversity not taken hold?',
      options: [
        {
          name: 'A.Societies that are characterised by higher levels of pathology.',
          value: 'Societies that are characterised by higher levels of pathology.'
        },
        {
          name: 'B.Societies that have made English mandatory for foreign-born citizens.',
          value: 'Societies that have made English mandatory for foreign-born citizens.'
        },
        {
          name: 'C.Societies where children have to attend mainstream schools.',
          value: 'Societies where children have to attend mainstream schools.'
        },
        {
          name: 'D.Societies where there is a majority of a particular ethnicity.',
          value: 'Societies where there is a majority of a particular ethnicity.'
        }
      ],
      answer: 'Societies that are characterised by higher levels of pathology.'
    },
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `Many ethnic-based societies exist where such ideals have yet to gain wide acceptance. Usually, this is the case because one ethnicity is dominant or in the much greater majority. In Japan, the Korean minority is stigmatised and excluded from positions of power in the society. Korean children, if they wish to be accepted by their peers, must attend Japanese schools and make Japanese their first language. They are made to feel ashamed of their Korean ethnic identity and culture.

      A similar movement on the part of conservatives in the US has sought to make English mandatory for foreign-born citizens. Such moves have the effect of making children feel stigmatised because of their ethnicity, and they turn that hatred from outside on themselves. Ethnic minorities, as a result, are usually characterised by higher levels of pathology, both physical and emotional, than the dominant population.`,
      question: 'Societies have ethnic tolerance and diversity not taken hold?',
      options: [
        {
          name: 'A.Societies that are characterised by higher levels of pathology.',
          value: 'Societies that are characterised by higher levels of pathology.'
        },
        {
          name: 'B.Societies that have made English mandatory for foreign-born citizens.',
          value: 'Societies that have made English mandatory for foreign-born citizens.'
        },
        {
          name: 'C.Societies where children have to attend mainstream schools.',
          value: 'Societies where children have to attend mainstream schools.'
        },
        {
          name: 'D.Societies where there is a majority of a particular ethnicity.',
          value: 'Societies where there is a majority of a particular ethnicity.'
        }
      ],
      answer: 'Societies where there is a majority of a particular ethnicity.'
    }
  ]
}

export const mcMultiAnsSampleData = {
  title: 'Sample question - Multiple choice, choose multiple answer (Reading)',
  subtitle: 'Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.',
  btn: 'CHECK ANSWER',
  questions: [
    {
      timer: 0,
      score: 0,
      recommendtime: 80,
      statement: `Modern liberal ideals of ethnic tolerance and diversity are taking hold in many places, but many ethnic-based societies exist where such ideals have yet to gain wide acceptance. Usually, this is the case because one ethnicity is dominant or in the much greater majority. In Japan, the Korean minority is stigmatised and excluded from positions of power in the society. Korean children, if they wish to be accepted by their peers, must attend Japanese schools and make Japanese their first language. They are made to feel ashamed of their Korean ethnic identity and culture.

      A similar movement on the part of conservatives in the US has sought to make English mandatory for foreign-born citizens. Such moves have the effect of making children feel stigmatised because of their ethnicity, and they turn that hatred from outside on themselves. Ethnic minorities, as a result, are usually characterised by higher levels of pathology, both physical and emotional, than the dominant population.`,
      question: 'In what kind of societies have ethnic tolerance and diversity not taken hold?',
      options: [
        {
          name: 'A.Societies that are characterised by higher levels of pathology.',
          value: 'Societies that are characterised by higher levels of pathology.'
        },
        {
          name: 'B.Societies that have made English mandatory for foreign-born citizens.',
          value: 'Societies that have made English mandatory for foreign-born citizens.'
        },
        {
          name: 'C.Societies where children have to attend mainstream schools.',
          value: 'Societies where children have to attend mainstream schools.'
        },
        {
          name: 'D.Societies where there is a majority of a particular ethnicity.',
          value: 'Societies where there is a majority of a particular ethnicity.'
        }
      ],
      answer: ['Societies that are characterised by higher levels of pathology.', 'Societies where children have to attend mainstream schools.']
    },
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `Many ethnic-based societies exist where such ideals have yet to gain wide acceptance. Usually, this is the case because one ethnicity is dominant or in the much greater majority. In Japan, the Korean minority is stigmatised and excluded from positions of power in the society. Korean children, if they wish to be accepted by their peers, must attend Japanese schools and make Japanese their first language. They are made to feel ashamed of their Korean ethnic identity and culture.

      A similar movement on the part of conservatives in the US has sought to make English mandatory for foreign-born citizens. Such moves have the effect of making children feel stigmatised because of their ethnicity, and they turn that hatred from outside on themselves. Ethnic minorities, as a result, are usually characterised by higher levels of pathology, both physical and emotional, than the dominant population.`,
      question: 'Societies have ethnic tolerance and diversity not taken hold?',
      options: [
        {
          name: 'A.Societies that are characterised by higher levels of pathology.',
          value: 'Societies that are characterised by higher levels of pathology.'
        },
        {
          name: 'B.Societies that have made English mandatory for foreign-born citizens.',
          value: 'Societies that have made English mandatory for foreign-born citizens.'
        },
        {
          name: 'C.Societies where children have to attend mainstream schools.',
          value: 'Societies where children have to attend mainstream schools.'
        },
        {
          name: 'D.Societies where there is a majority of a particular ethnicity.',
          value: 'Societies where there is a majority of a particular ethnicity.'
        }
      ],
      answer: ['Societies where there is a majority of a particular ethnicity.', 'Societies that are characterised by higher levels of pathology.']
    }
  ]
}


export const readingModuleRFillInBlankSampleData = {
  title: 'Sample Question - Reading: Fill in the blanks (Reading)',
  subtitle: 'Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.',
  btn: 'CHECK ANSWER',
  questions: [
    {
      timer: 0,
      score: 0,
      recommendtime: 60,
      statement: `Cultural nationalists endorse the belief that states are politically {{response}}
      entities with   clearly defined borders, a unified political and economic {{response}} that affects all similarly,
      and a set of legal and cultural practices shared by its {{response}} Nation - states, in such a  view, are imagined as more or less homogenous, culturally and ethnically: culture is produced  internally,
      within a country’s borders with little outside {{response}} and shared by the  country’s citizens equally.`,
      options: [
        'sentiment', 'distrust', 'principels', 'system', 'boundary', 'influence', 'plan', 'effect'
      ],
      answer: [
        'sentiment', 'boundary', 'influence', 'effect'
      ]
    },
    {
      timer: 0,
      score: 0,
      recommendtime: 50,
      statement: `Cultural nationalists endorse the belief that states are politically {{response}}
      entities with   clearly defined borders, a unified political and economic {{response}} that affects all similarly,
      and a set of legal and cultural practices shared by its Nation - states,
      in such a view, are imagined as more or less homogenous, culturally and ethnically: culture is produced internally,
      within a country’s borders with little outside {{response}} and shared by the country’s citizens equally.`,
      options: [
        'sentiment', 'distrust', 'principels', 'system', 'boundary', 'influence', 'plan', 'effect'
      ],
      answer: [
        'boundary', 'system', 'plan'
      ]
    }
  ]
}


export const essaySampleData = {
  questions: [
    {
      statement: `<p>More and more students are studying at universities abroad, either because it is cheaper, or because they feel they can receive a better education, or because it will provide them with greater professional opportunities. Which one do you think is the most important reason

      Choose which position you most agree with and discuss why you chose that position. Support your point of view with details from your own experiences, observations or reading</p>`,
      answer: ``
    },
    {
      statement: `<p>Students are studying at universities abroad, either because it is cheaper, or because they feel they can receive a better education, or because it will provide them with greater professional opportunities. Which one do you think is the most important reason

      Choose which position you most agree with and discuss why you chose that position. Support your point of view with details from your own experiences, observations or reading</p>`,
      answer: ``
    }
  ]
}
export const summarySampleData = {
  questions: [
    {
      statement: `<p>The United Nations Intergovernmental Panel on Climate Change (IPCC) has released a new report on anthropogenic climate change. The findings of the report include the following:</p>
      <ul>
      <li>
            The world's average surface temperature has increased by around 0.74°C over the past 100 years (1906 - 2005). A warming of about 0.2°C is projected for each of the next two decades.
      </li>
      <li>
      The best estimates for sea-level rise due to ocean expansion and glacier melt by the end of the century (compared to 1989 - 1999 levels) have narrowed to 28 - 58 cm, versus 9 - 88 cm in the 2001 report, due to improved understanding. However, larger values of up to 1 m by 2100 cannot be ruled out if ice sheets continue to melt as temperature rises.
      </li>
      <li>
      Sea ice is projected to shrink in both the Arctic and Antarctic regions. Large areas of the Arctic Ocean could lose year-round ice cover by the end of the 21st century if human emissions reach the higher end of current estimates. The extent of Arctic sea ice has already shrunk by about 2.7 per cent per decade since 1978, with the summer minimum declining by about 7.1 per cent per decade.
      </li>
      <li>
      Snow cover has decreased in most regions, especially in spring. The maximum extent of frozen ground in the winter/spring season decreased by about 7 per cent in the Northern Hemisphere over the latter half of the 20th century. The average freezing date for rivers and lakes in the Northern Hemisphere over the past 150 years has arrived later by some 5.8 days per century, while the average break-up date has arrived earlier by 6.5 days per century.
      </li>
      <li>
      It is "very likely" that precipitation will increase at high latitudes and "likely" it will decrease over most subtropical land regions. The pattern of these changes is similar to what has been observed during the 20th century.
      </li>
      </ul>`,
      answer: ``
    },
    {
      statement: `<p>Students are studying at universities abroad, either because it is cheaper, or because they feel they can receive a better education, or because it will provide them with greater professional opportunities. Which one do you think is the most important reason

      Choose which position you most agree with and discuss why you chose that position. Support your point of view with details from your own experiences, observations or reading</p>`,
      answer: ``
    }
  ]
}


export const questions = [
  {
    name: 'Question 1',
    id: 1,
    disabled: false,
    flag: ''
  },
  {
    name: 'Question 2',
    id: 2,
    disabled: false,
    flag: ''
  },
  {
    name: 'Question 3',
    id: 3,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 4',
    id: 4,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 5',
    id: 5,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 6',
    id: 6,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 7',
    id: 7,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 8',
    id: 8,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 9',
    id: 9,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 10',
    id: 10,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 11',
    id: 11,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 12',
    id: 12,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 13',
    id: 13,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 14',
    id: 14,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 15',
    id: 15,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 16',
    id: 16,
    disabled: true
  },
  {
    name: 'Question 17',
    id: 17,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 18',
    id: 18,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 19',
    id: 19,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 20',
    id: 20,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 21',
    id: 21,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 22',
    id: 2,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 23',
    id: 23,
    disabled: true,
    flag: ''
  },
  {
    name: 'Question 24',
    id: 24,
    disabled: true,
    flag: ''
  }
];
