import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { PopupComponent } from '../popup/popup.component';

export interface CanComponentDeactivate {
  confirm(): boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(private dialog: MatDialog, private activated:ActivatedRoute) { }

  async canDeactivate(component: CanComponentDeactivate) {
    return this.openDialog().then(res=>{
      if(res){
        console.log('deac', res);
        this.activated.queryParams.subscribe(res=>{
          console.log('logout--', res);
          if(res.logout){
            const bc = new BroadcastChannel('test_channel');
            bc.postMessage('logout');
            this.dialog.closeAll()
          }
        })
        return true
      }
    }).catch(err =>{
      return false
    })
  }


  public async openDialog() {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '530px',
      height: '250px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to discard the changes made on this page?',
        enableBtn: true,
        enableIcon: false,
        buttonText: {
          ok: 'Leave',
          cancel: 'Stay'
        }
      }
    });

    return await dialogRef.afterClosed().toPromise();
  }
}
