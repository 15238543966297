<div class="card p-3 my-3">
  <div class="card-content pb-3">
    <h5 class="title" id="data?.title{{data?.id}}">{{data?.title}}</h5>
  </div>
  <div class="card-footer p-0 px-2 pt-3">
    <div class="row">
      <span class="px-2" id="subtitle{{data?.id}}">{{count[data.type]}} Questions</span>
      <button class="btn ml-auto btn-primary" (click)="action.emit(data)" id="btn{{data?.id}}">{{data?.btn}}</button>
    </div>
  </div>
</div>
