import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JppService } from 'src/app/providers/jpp.service';

@Component({
  selector: 'app-write-box',
  templateUrl: './write-box.component.html',
  styleUrls: ['./write-box.component.scss'],
})
export class WriteBoxComponent implements OnInit {
  _answer = [];
  _writingAnswer;
  _wordCountVal = [];
  form: FormGroup;
  @Input() count = `0 / 200`;
  @Input() rows = 6;
  @Input() index = 0;
  @Input() length;
  disableTextArea;
  @Input() wordCount = '';
  @Input() safelimit = 200;
  @Input()
  get answer() {
    return this._answer[this.index];
  };
  set answer(val) {
    console.log('val', val);
    this._answer[this.index] = (val != 'undefined') ? val : '';
  };
  @Input()
  get wordCountVal() {
    return this._wordCountVal[this.index];
  }
  set wordCountVal(val) {
    if(this.answer){
      this.wordCount = this.answer
      ? this.answer.split(/[\n\r\s]+/g).filter((word) => {
        return word.length > 0;
      })
      : 0;
    } else {
      this.wordCount = '';
    }
    console.log('{ val }', this.wordCount,this.wordCount.length, this.answer, this.index, val);
    this._wordCountVal[this.index] = val;
  }
  @Input() maxWordCount = 200;
  @Input() disabled = false;

  textMessage: string;
  msgHideAndShow: boolean;
  selectedText: string;
  customInput: ElementRef;
  @Output() wordCountEvt = new EventEmitter();
  @Output() reset = new EventEmitter();
  constructor(public service: JppService) { }

  ngOnInit(): void {
    for (let i = 0; i < this.length; i++) {
      this._answer[i] = '';
      this.wordCountVal[i] = 0;
    }
  }

  handleTimerEvent(e) {
    console.log(e);
    if (e) {
      if (e.action === 'done') {
        this.form.get('answer').disable();
      }
    }
  }

  wordCounter(event) {
    const answer: any = this._answer[this.index];
    this.wordCount = answer
      ? answer.split(/[\n\r\s]+/g).filter((word) => {
        return word.length > 0;
      })
      : 0;
    if (this.wordCount) {
      if (this.wordCount.length >= this.maxWordCount) {
        if (/^([A-Za-z0-9 ])$/.test(event.key)) {
          event.preventDefault();
          return false;
        }
      } else {
        this.service.wordCount.next(this.wordCount.length);
        this.wordCountEvt.emit(this.wordCount.length)
        return true;
      }
    }
    console.log('this.wordCount', this.wordCount, this._wordCountVal[this.index]);
  }

  copy() {
    console.log(this.customInput);
    var input = document.getElementById('answer-box-writing') as HTMLInputElement;
    console.log(input.selectionStart);
    if (input.selectionStart == input.selectionEnd) {
      return;
    }

    let selected = input.value.slice(input.selectionStart, input.selectionEnd);
    console.log({ selected });
    if (selected) {
      this.selectedText = selected;
    }
  }

  paste() {
    var input = document.getElementById('answer-box-writing') as HTMLInputElement;
    console.log('selected', this.selectedText, this._answer[this.index], this.wordCount.length, this.maxWordCount);
    input.focus();
    const answer: any = this._answer[this.index];
    this.wordCount = answer
      ? answer.split(/[\n\r\s]+/g).filter((word) => {
        return word.length > 0;
      })
      : 0;
    // if (this.wordCount) {
    if (this.wordCount.length <= this.maxWordCount || !this.wordCount) {
      const tempVal = JSON.parse(JSON.stringify(this._answer[this.index] + this.selectedText));
      const tempWordCount = tempVal ? tempVal.split(/[\n\r\s]+/g).filter((w) => {
        return w.length > 0;
      }) : 0;
      console.log(JSON.stringify(tempVal));
      console.log({ tempWordCount });

      if (tempVal) {
        console.log('paste', tempWordCount['length'], this.maxWordCount)

        if (tempWordCount['length'] <= this.maxWordCount) {
          if (this.selectedText) {
            input['value'] = input['value'] + this.selectedText;
            this._answer[this.index] = input['value'];
            console.log('this.answer)', this._answer[this.index]);
            this.updateCount();
            console.log('this.wordCount.length', this.wordCount.length);
          }
        }
      }
    }
    // }
  }

  cut() {
    var input = document.getElementById('answer-box-writing') as HTMLInputElement;
    console.log(input.selectionStart);
    if (input.selectionStart == input.selectionEnd) {
      return;
    }
    let selected = input.value.slice(input.selectionStart, input.selectionEnd);
    this.selectedText = selected;

    var result = input.value.split('');
    result.splice(
      input.selectionStart,
      input.selectionEnd - input.selectionStart
    );
    input.value = result.join('');
    this._answer[this.index] = input.value;
    console.log('this.answer', this._answer[this.index]);
    this.updateCount();
  }

  updateCount() {
    const answer: any = this._answer[this.index];
    this.wordCount = answer
      ? answer.split(/[\n\r\s]+/g).filter((word) => {
        return word.length > 0;
      })
      : 0;
    this.service.wordCount.next(this.wordCount.length);
    this.wordCountEvt.emit(this.wordCount.length)
  }

  autogrow() {
    let textArea = document.getElementById("answer-box-writing")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
