import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
  constructor(private router: Router) { }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (this.router.url.includes('/home')) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (this.router.url.includes('/home')) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (this.router.url.includes('/home')) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  }
}
