import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';
@Component({
  selector: 'app-m-c-multiple-ans',
  templateUrl: './m-c-multiple-ans.component.html',
  styleUrls: ['./m-c-multiple-ans.component.scss']
})
export class MCMultipleAnsComponent implements OnInit {
  _data;
  transformData;
  startcounter = true;
  binding;
  options: NodeListOf<HTMLElement>;
  @ViewChildren('optionslist', { read: ElementRef }) optionslist: QueryList<any>;
  @ViewChildren('optionswrapper', { read: ElementRef }) optionswrapper: QueryList<any>;
  apiquestionList: any;
  questionsIndexList: any[];
  _BackUpdata: any;
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    console.log(val);
    this.transform(val);
    this._data = val;
  }
  @Output() back = new EventEmitter();
  score: any = 0;
  recommendtime: any = 0;
  showans: boolean;
  questionIndex = 0;
  selectedAns;
  chkAns = false;
  valid = false;
  questionblkActive;
  questions = [];
  mcqMultiAnsLabel = {
    title: 'Sample question - Multiple choice, choose multiple answer (Reading)',
    subtitle: 'Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.',
    btn: 'CHECK ANSWER'
  }
  constructor(public domsanitize: DomSanitizer, public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  transform(val) {
    this.transformData = val.questions ? { ...val.questions[this.questionIndex] } : {};
    // this.transformData = val.questions ? val.questions[this.questionIndex] : {};
    this.recommendtime = this.transformData.question ? this.transformData.question.recommendtime : 0;
    console.log(this.transformData);
    this.valid = false;

    if (this._data && this._data.questions[this.questionIndex]['isTouched']) {
      setTimeout(() => {
        this.options = document.getElementsByName(`option-${this.transformData?.unqId}`);
        for (let i = 0; i < this.options.length; i++) {
          if (this._data.questions[this.questionIndex]['userAns'].some(e => e == i)) {
            console.log({ i });
            this.options[i]['checked'] = true;
            this.checkAns();
          }
        }
      })
    }
  }

  changeQuestion(i) {
    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    if (this._data.questions[this.questionIndex]) {
      this.transform(this._data);
    }
    this.showans = false;
    this.startcounter = true;
    this.score = 0;
  }

  getCount(val) {
    console.log(val);
    this.transformData.timer = val;
  }

  replaceLineBreaks(statement: string): string {
    return statement.replace(/\n/g, '<br>');
  }


  checkAns() {
    let score = 0;
    this._data.questions[this.questionIndex]['isTouched'] = true;
    this.options = document.getElementsByName(`option-${this.transformData?.unqId}`);
    const options = this.optionslist.toArray();
    const optionsWrapper = this.optionswrapper.toArray();
    console.log(options, this.transformData.answer);
    if (options && !options.some(el => el.nativeElement.checked === true)) {
      return;
    }
    for (let i = 0; i < options.length; i++) {
      if (optionsWrapper[i] && optionsWrapper[i].nativeElement &&
        this.transformData.answer.some(el => el.data === options[i].nativeElement.value)) {
        optionsWrapper[i].nativeElement.classList.add('right-ans');
      }
      if (options[i].nativeElement && options[i].nativeElement.checked) {
        this.valid = true;
        this._data.questions[this.questionIndex]['userAns'].push(i);
        console.log('options[i].nativeElement.value', options[i].nativeElement.value);
        if (this.transformData.answer.some(el => el.data === options[i].nativeElement.value)) {
          if (optionsWrapper[i] && optionsWrapper[i].nativeElement) {
            optionsWrapper[i].nativeElement.classList.add('right-ans');
          }
          score += 1;
        } else {
          if (optionsWrapper[i] && optionsWrapper[i].nativeElement) {
            optionsWrapper[i].nativeElement.classList.remove('right-ans');
            optionsWrapper[i].nativeElement.classList.add('wrong-ans');
          }
          score -= 1;
        }
      }
    }
    console.log({ score });
    this.transformData.score = (score <= 0) ? 0 : score;
    this.score = (score <= 0) ? 0 : score;
    this.showans = true;
    this.startcounter = false;
    this.chkAns = true;
  }

  chkOptions(optVal, i) {
    const options = this.optionslist.toArray();
    if (optVal) {
      if (options[i].nativeElement && options[i].nativeElement.checked) {
        if (this.transformData.answer.some(el => el === options[i].nativeElement.value)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  filter(e) {
    if (this.questions && e['check']) {
      this.questions = [...data.questions];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions);
    } else {
      this.questions = [...data.questions];
    }
  }

  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 'mcq_multi_ans';
          });
          this.apiquestionList = questionList[0];
          console.log({ questionList });
          const questions = [...questionList];
          console.log('questions', questions);
          // this._data = questions[0];
          this._data = JSON.parse(JSON.stringify(questions[0]));
          this._BackUpdata = JSON.parse(JSON.stringify(questions[0]));
          console.log('data....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          this._data.questions.map(e => {
            return e['userAns'] = []
          })
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.questionsIndexList.push(template);
          });
          this.questions = [...this.questionsIndexList];
          // this.questions = this.questionsIndexList;
          console.log('this.questions', this.questions);
          this.transform(this._data);
        }
      }
    });
  }


  reset() {
    if (this._data.questions[this.questionIndex]['isTouched']) {
      // this._data.questions[this.questionIndex] = JSON.parse(JSON.stringify(this._BackUpdata.questions[this.questionIndex]))
      console.log('test', this._data.questions[this.questionIndex], this._BackUpdata.questions[this.questionIndex])
      // this.transformData= {};
      setTimeout(() => {

        this.options = document.getElementsByName(`option-${this.transformData?.unqId}`);
        const options = this.optionslist.toArray();
        const optionsWrapper = this.optionswrapper.toArray();
        console.log(options, this.transformData.answer);
        for (let i = 0; i < options.length; i++) {
          if (optionsWrapper[i] && optionsWrapper[i].nativeElement) {
            optionsWrapper[i].nativeElement.classList.remove('right-ans');
            optionsWrapper[i].nativeElement.classList.remove('wrong-ans');
          }
        }

        this._data.questions[this.questionIndex]['userAns'] = []
        // this.transform(this._data);
        this.showans = false;
        this.startcounter = false;
        this.startcounter = true;
      })
      console.log('reset');
    }
  }
}
