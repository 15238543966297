import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList, ComponentFactoryResolver, HostListener } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';
@Component({
  selector: 'app-fill-in-blanks',
  templateUrl: './fill-in-blanks.component.html',
  styleUrls: ['./fill-in-blanks.component.scss']
})
export class FillInBlanksComponent implements OnInit {
  _data;
  transformData;
  startcounter = true;
  binding;
  apiquestionList: any;
  questionsIndexList: any[];
  isError: any = [];
  isSuccess: any = [];
  private _BackUpdata: any;
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this.transform(val);
    this._data = val;
  }
  @Output() back = new EventEmitter();
  score: any = 0;
  recommendtime: any = 0;
  showans: boolean;
  questionIndex = 0;
  questionblkActive;
  questions = [];
  fillInTheBlkRWLabel = {
    title: 'Sample question - Fill in the blanks (Reading and Writing)',
    subtitle: 'Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.',
    btn: 'CHECK ANSWER',
  }
  @HostListener('document:click', ['$event.target'])
  reset(targetElement) {
    const data = targetElement ? targetElement :'';
    console.log('targetElement', data.classList.contains('dropdown-box'), targetElement);
    if (data && data.classList.contains('dropdown-box') && this._data.questions[this.questionIndex]['isTouched']) {
      // this._data.questions[this.questionIndex] = JSON.parse(JSON.stringify(this._BackUpdata.questions[this.questionIndex]))
      console.log('test', this._data.questions[this.questionIndex], this._BackUpdata.questions[this.questionIndex])
      this.transformData= {};
      setTimeout(()=>{
        // this._data.questions[this.questionIndex]['userAns'] = [];
        this.isError[this.questionIndex] =false;
        this.isSuccess[this.questionIndex] =false;
        this.transform(this._data,'reset');
        this.showans = false;
        this.startcounter = false;
        this.startcounter = true;
      })
      console.log('reset');
    }
  }
  constructor(public domsanitize: DomSanitizer, public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
  }


  changeQuestion(i) {
    console.log('return', this.questionIndex, i);

    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    console.log('(this._data.questions[this.questionIndex]', this._data.questions[this.questionIndex]);
    if (this._data.questions[this.questionIndex]) {
      this.transform(this._data, '');
      this.showans = false;
      this.startcounter = false;
      this.startcounter = true;
    }
    console.log('this.isError', this.isError);
  }


  getCount(val) {
    console.log(val);
    this.transformData.timer = val;
  }

  transform(val, key?) {
    if (val && val.questions) {
      this.transformData = { ...val.questions[this.questionIndex] };
      this.recommendtime = this.transformData.question ? this.transformData.question.recommendtime : 0;
      console.log('transformData', this.transformData);
      /* creating dropdown template */
      let dropdownTemplate = ``;
      const transformDataOptions = this.transformData.question ? this.transformData.question.options : [];
      this.transformData['names'] = [];
      if (this.transformData.question) {
        this.transformData.question.options.forEach((e, i) => {
          this.transformData['names'].push(`options${i}`)
        });
      }
      const dropdownTemplates = [];
      this.transformData.names.forEach((optName, i) => {
        dropdownTemplate = '';
        dropdownTemplate += ` <div class="dropdown-box m-1" id="${optName}-dropdown">
      <div class="dropdown-box__current" id="container${optName}" tabindex="1">
       <div class="dropdown-box__value" id="${optName}-dropbox-default">
        <input class="dropdown-box__input" type="radio" id="${optName}-default" value="test" name="${optName}" checked="checked"  />
        <p class="dropdown-box__input-text o-none"  id="${optName}-txt" >-</p>
      </div> `;

        transformDataOptions[i].forEach((options, j) => {
          const optionscode = `<div class="dropdown-box__value" id="dropbox${optName}${j}">
            <input class="dropdown-box__input" type="radio"
            id="${optName}${j}" value="${options}" name="${optName}"  />
            <p class="dropdown-box__input-text" id="${optName}${j}-txt">${options}</p>
          </div>
          `;
          dropdownTemplate += optionscode;
        });
        dropdownTemplate +=
          `<img class="dropdown-box__icon" id="${optName}-img" src="./assets/icons/dropdown-arrow.svg" alt="Arrow Icon" aria-hidden="true"/>
        </div>
        <ul class="dropdown-box__list mt-1 px-0">`;
        transformDataOptions[i].forEach((el, j) => {
          const label = `<li class="my-0 py-0 border-line">
            <label class="dropdown-box__option" for="${optName}${j}" aria-hidden="aria-hidden">${el}</label>
          </li>`;
          dropdownTemplate += label;
        });
        dropdownTemplate += ` </ul>
        </div>
        </div>`;
        dropdownTemplates.push(dropdownTemplate);
      });

      this.updateDropdownTemplate(dropdownTemplates, key);
    }
  }

  replaceLineBreaks(statement: string): string {
    return statement.replace(/\n/g, '<br>');
  }

  updateDropdownTemplate(template, key?) {
    const searchTerm = 'response}}';
    const newArray = [];
    let replacedCount = 0;
    this.transformData.question.statement = this.transformData.question.statement.replace(/\n/g, '<br>');
    const tArray = this.transformData.question ? this.transformData.question.statement.split('{{') : [];
    tArray.forEach((el, i) => {
      if (el.match(searchTerm)) {
        newArray[i] = tArray[i].replace(searchTerm, template[replacedCount]);
        replacedCount += 1;
      } else {
        newArray[i] = el;
      }
    });
    const statement = newArray.join('');
    // console.log("joe",newArray);
    this.transformData.question['statement'] = JSON.parse(JSON.stringify(statement));
    console.log('this._data.questions[this.questionIndex][isTouched]', this._data.questions[this.questionIndex]['isTouched']);
    if (this._data.questions[this.questionIndex]['isTouched']) {
      setTimeout(() => {
        const ans = this._data.questions[this.questionIndex]['userAns'];
        if (ans) {
          console.log({ ans });
          for (let i = 0; i < ans.length; i++) {
            console.log('element', ans[i]);
            console.log('anssss', ans[i]);
            const d = document.getElementById(`${ans[i]}`) as HTMLInputElement;
            console.log({ d });
            if (d) {
              d.checked = true;
            }
          }
          if(!key){
            this.checkAns();
          }
        }
      })
    }
  }

  checkAns() {
    let score = 0;
    const optionLabels = this.transformData.names;
    // if (this._data.questions[this.questionIndex]['isTouched']) {
    //   this._data.questions[this.questionIndex]['userAns'] = [];
    // }
    this._data.questions[this.questionIndex]['isTouched'] = true;
    console.log('this._data.questions', this._data.questions,this.questionIndex );
    for (let j = 0; j < optionLabels.length; j++) {
      const ele = document.getElementsByName(optionLabels[j]);
      for (let i = 1; i < ele.length; i++) {
        if (ele[i]['checked']) {
          // right option
          this._data.questions[this.questionIndex]['userAns'].push(`options${j}${i - 1}`);
          if (ele[i]['value'] === this.transformData.answer[j]) {
            const dropdown = document.getElementById(optionLabels[j] + (i - 1) + '-txt');
            dropdown.classList.add('correct-option');
            const dropdownBox = document.getElementById('dropbox' + optionLabels[j] + (i - 1));
            dropdownBox.classList.add('correct-option');
            const dropdownContainer = document.getElementById('container' + optionLabels[j]);
            dropdownContainer.style.pointerEvents = 'none';
            const img = document.getElementById(optionLabels[j] + '-img');
            img['src'] = './assets/icons/tick.svg';
            score += 1;
            const data = document.getElementById(`${this.transformData.names[j]}-dropdown`);
            console.log('right----option----', j, this.transformData.names, `${this.transformData.names[j]}-dropdown`);
            if (data) {
              data.classList.add('counter-index-blk-ans');
              setTimeout(() => {
                document.getElementById(`${this.transformData.names[j]}-dropdown`).setAttribute("data-alt-pseudo", (j + 1).toString());
              })
            }
            break;
          } else {
            // wrong option
            // this._data.questions[this.questionIndex]['userAns'].push(ele[i]['value']);
            const dropdown = document.getElementById(optionLabels[j] + (i - 1) + '-txt');
            if (dropdown && !dropdown.className.includes('correct-option')) {
              dropdown.classList.add('wrong-option');
              const dropdownBox = document.getElementById('dropbox' + optionLabels[j] + (i - 1));
              dropdownBox.classList.add('wrong-option');
            }
            const dropdownContainer = document.getElementById('container' + optionLabels[j]);
            dropdownContainer.style.pointerEvents = 'none';
            console.log('right----option----', j, this.transformData.names, `${this.transformData.names[j]}-dropdown`);
            const data = document.getElementById(`${this.transformData.names[j]}-dropdown`);
            if (data) {
              data.classList.add('counter-index-blk');
              setTimeout(() => {
                document.getElementById(`${this.transformData.names[j]}-dropdown`).setAttribute("data-alt-pseudo", (j + 1).toString());
              })
            }
          }
        } else {
          // this._data.questions[this.questionIndex]['userAns'].push('');
          const dropdownLabeltxt = document.getElementById(optionLabels[j] + `-txt`);
          dropdownLabeltxt.classList.add('wrong-option-txt');
          const img = document.getElementById(optionLabels[j] + '-img');
          img['src'] = './assets/icons/close.svg';
          const dropdownBoxDefault = document.getElementById(optionLabels[j] + '-dropbox-default');
          dropdownBoxDefault.classList.add('wrong-option');
          const dropdownContainer = document.getElementById('container' + optionLabels[j]);
          dropdownContainer.style.pointerEvents = 'none';
          const data = document.getElementById(`${this.transformData.names[j]}-dropdown`);
          if (data) {
            data.classList.add('counter-index-blk');
            setTimeout(() => {
              document.getElementById(`${this.transformData.names[j]}-dropdown`).setAttribute("data-alt-pseudo", (j + 1).toString());
            })
          }
        }
      }
    }
    this.transformData.score = score;
    this.score = score;
    this.showans = true;
    this.startcounter = false;
    console.log('this.transformData.answer', this.transformData.answer['length']);
    if(this.score == this.transformData.answer['length']){
      this.isSuccess[this.questionIndex] = true;
      this.isError[this.questionIndex] =  false;
      return;
    } else{
      this.isError[this.questionIndex] = (this.score == this.transformData.answer['length']) ? false : true;
    }
    console.log('this._data.questions[this.questionIndex][userAns]', this._data.questions[this.questionIndex]['userAns']);
  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  filter(e) {
    if (this.questions && e['check']) {
      this.questions = [...data.questions];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions);
    } else {
      this.questions = [...data.questions];
    }
  }


  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 'reading_and_writing';
          });
          this.apiquestionList = questionList[0];
          console.log({ questionList });
          const questions = [...questionList];
          console.log('questions', questions);
          this._BackUpdata = JSON.parse(JSON.stringify(questions[0]));
          this._data = JSON.parse(JSON.stringify(questions[0]));
          this._data.questions.map(e => {
            return e['userAns'] = []
          })
          console.log('data....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.isError.push(false);
            this.isSuccess.push(false);
            this.questionsIndexList.push(template);
          });
          this.questions = [...this.questionsIndexList];
          console.log('this.questions', this.questions);
          this.transform(this._data);
        }
      }
    });
  }
}
