<div class="col-lg-12 mct-3 px-5 pxc-5 py-4 pb-5 dashboard">
  <div class="col-lg-11 mx-auto px-5 pxc-5">
    <div class="col-lg-12 px-5">
      <div class="card px-lg-5 py-4">
        <div class="col-lg-12 pt-1 px-lg-4">
          <div class="col-lg-12 px-0">
            <div class="col-lg-12 px-0">
              <div class="row">
                <div class="col-lg-1 px-0 my-auto">
                  <a id="back" (click)="back.emit()" class="back-action"><img src="./assets/icons/arrow-back.svg" width="10px" class="arrow-back" alt="arrow-back" id="arrow-back-icon"> <span class="pl-2">Back</span></a>
                </div>
                <div class="col-lg-10 px-0">
                  <div class="title-blk pl-5 pxc-5 py-2 col-lg-12 mx-auto">
                    <h1 class="px-3 mb-0" id="title{{data?.id}}">{{data?.title}}</h1>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="col-lg-12 px-lg-5 pxc-5 pb-4 content-blk">
              <div class="col-lg-10 mx-auto">
                <p class="intro-text"  id="subtitle{{data?.id}}">{{data?.subtitle}}</p>
                <img [src]="data?.image" class="intoImage" alt="introImage" id="introImage" />
              </div>
            </div>
            <hr class="mt-1"/>
            <div class="col-lg-12 text-right pt-2">
              <button class="btn ml-auto btn-primary" (click)="start.emit(data)" id="btn">{{data?.btn}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
