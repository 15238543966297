<div class="col-lg-12 write-box px-0">
  <div class="write-box-header">
    <ul class="header">
      <li class="unselectable">
        <button type="button" (click)="copy()" class="li-btn">Copy</button>
      </li>
      <li class="unselectable">
        <button type="button" class="li-btn" (click)="cut()">Cut</button>
      </li>
      <li class="unselectable">
        <button type="button" (click)="paste()" class="li-btn">Paste</button>
      </li>
      <li class="ml-auto count">
        <span>{{(wordCount?.length || 0) + ' / ' + (maxWordCount || '200')}} Word Limit</span>
      </li>
    </ul>
  </div>
  <div class="write-box-content" (click)="reset.emit()">
    <textarea spellcheck="false" autocorrect="off"  [disabled]="disabled" [ngClass]="{'green-box':wordCount?.length >= safelimit, 'red-box':(wordCount?.length==maxWordCount)}"
      name="answer" #inputElement SingleSpaceOnly [(ngModel)]="_answer[index]"
      [value]="_answer[index] ? _answer[index] : ''" class="form-control write-text-box" id="answer-box-writing" rows="{{rows}}"
      (keydown)="wordCounter($event);autogrow()" (paste)="false"></textarea>
  </div>
</div>
