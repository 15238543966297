export const homeData = [
  {
    title: 'Exam Tips',
    content: `Find out some simple tips about how <br/> to perform well on PTE Academic.`,
    btnTitle: 'More',
    bgImg: './assets/images/exam-tips.webp'
  },
  {
    title: 'Buy now',
    content: `Purchase PTE Academic practice tests <br/> to try before you sit the exam.`,
    btnTitle: 'Buy',
    bgImg: './assets/images/buy-now.webp'
  }
]
