export class RegisterHelper {
  constructor() { }
  mapFormDataToServiceRegister(formvalue) {
    if (formvalue) {
      const payload = {
        name: formvalue.fullName,
        email: formvalue.email,
        country: formvalue.countryOrLocation,
        mobile: +formvalue.mobileNumber,
        password: formvalue.password,
        cpassword: formvalue.confirmPassword
      };
      return payload;
    }
  }
}
