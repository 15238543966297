<div class="ans-blk" *ngIf="answerlist?.length>0 && (template === 'default')">
  <div class="title-blk px-3 pxc-5">
    <p class="mb-0" id="title">Correct answer</p>
  </div>
  <div class="col p-0">
    <ul>
      <li class="py-3" *ngFor="let ans of answerlist;let i=index">
        {{ans?.data?.slice(0, 2)}}{{ans?.data?.slice(2, ans?.length)}}
        <!-- <i *ngIf="ans && ans!=''" class="fa fa-bars px-1"></i> -->
        
      </li>
    </ul>
  </div>
</div>
<div class="ans-blk-template {{fullwidth ? 'fullwidth': ''}}"
  *ngIf="answerlist?.length>0 && (template === 'fill&blanks')">
  <div class="title-blk px-3 pxc-5">
    <p class="mb-0" id="title">Correct answer</p>
  </div>
  <div class="col p-0">
    <ul>
      <li class="py-1" *ngFor="let ans of answerlist;let i=index">
        <span>
          <span class="font-weight-bold q-blk">{{i+1}}</span> {{ans}}
        </span>
      </li>
    </ul>
  </div>
</div>

<div class="col-lg-12 ans-blk p-0"  *ngIf="(template === 'summary')">
  <div class="title-blk px-3 pxc-5">
    <p class="mb-0" id="title">Correct answer</p>
  </div>
  <div class="ans-blk-template card p-4 d-flex" >
    {{answerlist ? answerlist: 'Not provided' }}
  </div>
</div>
