<mat-dialog-content>
  <div class="col text-center p-1">
    <p  *ngIf="enableIcon">
      <img src="./assets/icons/info.svg" width="40px" alt="info-icon">
    </p>
    <p class="p-2 ptext-white title mb-0"> {{title}}</p>
  </div>
  <p class="p-4 text-center message mb-0" *ngIf="message">
    {{message}}
  </p>
</mat-dialog-content>
<mat-dialog-actions *ngIf="enableBtn">
  <div class="col text-center" >
    <div class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-4 col-6 offset-lg-2 pl-lg-0 pr-lg-0  {{ cancelButtonText ?'': 'col-6  mx-auto pl-lg-0 pr-lg-0'}}">
        <button type="button" id="yes" class="btn btn-default btn-lg center-block w-100" (click)="onConfirmClick(true)"
          tabindex="-1">
          {{confirmButtonText}}
        </button>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-4 col-6 offset-lg-2 pl-lg-0 pr-lg-0" *ngIf="cancelButtonText">
        <button type="button" id="no" (click)="onConfirmClick(false)" mat-dialog-close
          class="btn btn-custom btn-lg center-block w-100">
          {{cancelButtonText}}
        </button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
