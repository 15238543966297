export const address = {
  lineOne: 'Suite 102 level 1, 32 Yorkstreet,',
  lineTwo: 'Sydney, NSW Australia 2000',
  email: 'vivekssj7@gmail.com',
  phoneNumber: '+61 450 451 537'
}
export const footerLinks = [
  {
    name: 'Home',
    link: '#'
  },
  {
    name: 'About Us',
    link: '#'
  },
  {
    name: 'Book Exam',
    link: '#'
  },
  {
    name: 'Contact Us',
    link: '#'
  }
];
