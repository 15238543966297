import { hostViewClassName } from '@angular/compiler';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-score-and-timer',
  templateUrl: './score-and-timer.component.html',
  styleUrls: ['./score-and-timer.component.scss']
})
export class ScoreAndTimerComponent implements OnInit, OnDestroy {
  _counter;
  _startCounter;
  chktimer;
  @Input() pause = false;
  @Input()
  get counter() {
    return this._counter;
  }
  set counter(val) {
    if (this.chktimer) {
      clearInterval(this.chktimer);
    }
    if ((val !== 0 || val === 'undefined' ) && !this.pause) {
      this.incCount();
    }
    this._counter = val ? val : 0;
  };
  @Input() score = 0;
  @Input() timeRecommended = 0;
  @Input()
  get startcounter() {
    return this._startCounter;
  }
  set startcounter(v) {
    if (v) {
      if (this.chktimer) {
        clearInterval(this.chktimer);
      }
      this.incCount();
    } else {
      this.count.emit(this._counter);
      if (this.chktimer) {
        clearInterval(this.chktimer);
      }
    }
    this._startCounter = v;
  }
  @Output() count = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.incCount();
  }

  public incCount() {
    this._counter = 0;
    if (this.chktimer) {
      clearInterval(this.chktimer);
    }
    this.chktimer = setInterval(() => {
      this._counter += 1;
    }, 1000);
  }

  start() {
    this.incCount();
  }

  ngOnDestroy() {
    if (this.chktimer) {
      clearInterval(this.chktimer);
    }
  }
}
