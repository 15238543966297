import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, enableProdMode, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
/* components */
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './shared/layout/layout.component';
/* service */
import { JppService } from './providers/jpp.service';
import { AuthService } from './providers/authguard/auth.service';
import { SharedDirectiveModule } from './shared/directives/shared.directive.module';
import { HttpTokenInterceptor } from './providers/httpInterceptor/http-token-interceptor';
import { SharedModule } from './shared/shared.module';
import { ConfirmationPopupComponent } from './providers/confirmation-popup/confirmation-popup.component';
import { BlockCopyPasteDirective } from './shared/pipe/copy-paste.directive';
import { LandingComponent } from './pages/landing/landing.component';
import { VerifyComponent } from './pages/verify/verify.component';
enableProdMode();
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RegisterComponent,
    HomeComponent,
    ClickOutsideDirective,
    LoginComponent,
    LayoutComponent,
    ConfirmationPopupComponent,
    BlockCopyPasteDirective,
    LandingComponent,
    VerifyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedDirectiveModule
  ],
  providers: [JppService, AuthService,	{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true
  }],
  entryComponents:[ConfirmationPopupComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
