import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-box',
  templateUrl: './drag-box.component.html',
  styleUrls: ['./drag-box.component.scss']
})
export class DragBoxComponent implements OnInit {
  enabledragHover: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  drop(ev) {
    console.log(ev);
    ev.preventDefault();
    const src = document.getElementById(ev.dataTransfer.getData('text'));
    const srcParent = src ? src.parentNode : null;
    const tgt = ev.currentTarget.firstElementChild;

    if (tgt) {
      ev.currentTarget.replaceChild(src, tgt);
      srcParent.appendChild(tgt);
    } else {
      const data = ev.dataTransfer.getData('text');
      ev.target.appendChild(document.getElementById(data));
    }
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    ev.dataTransfer.setData('text', ev.target.id);
  }

  dragHover(e){
    console.log('res');
    if(e){
      this.enabledragHover = e;
    } else {
      this.enabledragHover = e;
    }
  }

}

