import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JppService } from 'src/app/providers/jpp.service';
import { StorageHelper } from 'src/app/shared/helper/helper';
import * as data from './home.data';
import { HomeHelper } from './home.helper';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeHelper]
})
export class HomeComponent implements OnInit {
  homecardData = data.homeData;
  openRegister = false;
  openLogin = false;
  openVerify = false;
  clickedOutsideCount = 0;
  template;
  options;
  actionData;
  @ViewChild('registerFormElement') registerFormElement;
  @ViewChild('loginFormElement') loginFormElement;
  apiInprogress: boolean;
  storageListerner;
  dialogRef: any;
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.registerFormElement) {
      const clickedInsideRegisterForm = this.registerFormElement.nativeElement.contains(targetElement);
      const refId = targetElement ? (targetElement as Element).id : '';
      console.log({ refId });
      if (!clickedInsideRegisterForm && refId !== 'showPassword' && refId !== 'togglePassword' && refId !== 'registerBtn') {
        this.openRegister = false;
      }
    }
    if (this.loginFormElement) {
      const clickedInsideLoginForm = this.loginFormElement.nativeElement.contains(targetElement);
      const refId = targetElement ? (targetElement as Element).id : '';
      if (!clickedInsideLoginForm && refId !== 'forgot-password' && refId !== 'signInRegistrationForm' && refId != 'signInBtn' &&
        refId !== 'showPassword' && refId !== 'togglePassword' && refId != 'showRsPassword' && refId != 'toggleRsPassword') {
        this.openLogin = false;
      }
    }
  }

  constructor(
    public activatedRouter: ActivatedRoute,
    public router: Router,
    public service: JppService,
    private storageHelper: StorageHelper,
    public dialog: MatDialog,
    public secureStorage: StorageHelper,
    private helper: HomeHelper) { }

  ngOnInit(): void {
    localStorage.clear();
    this.service.loggedIn = false;
    if (this.dialog) {
      this.dialog.closeAll();
    }
    this.activatedRouter.queryParams.subscribe(params => {
      if (params.resetcode) {
        console.log(params);
        this.openRegister = false;
        this.openLogin = true;
        this.options = 'resetPassword';
      }
    });
  }

  closeRegisterForm() {
    this.openRegister = false;
  }

  action(e) {
    if (e) {
      this.options = '';
      this.template = e.to;
      
      if (e.to == 'verify') {
        this.openRegister = false;
        this.openVerify = true;
        this.actionData = e.data;
      } else if (e.to == 'login') {
        this.openVerify = false;
        this.openLogin = true;
      }
    }
  }

  changeForm(e) {
    if (e) {
      this.options = e;
      console.log(e);
      this.openLogin = true;
    }
  }

  login(formData) {
    console.log('login', formData);
    let payload;
    if (formData) {
      switch (formData.key) {
        case 'login': {
          payload = this.helper.mapLoginformDataToService(formData['data']);
          if (payload) {
            if (this.apiInprogress) {
              return;
            }
            this.apiInprogress = true;
            this.service.login(payload).subscribe(res => {
              console.log("res",res);
              this.apiInprogress = false;
              if (res['response']['status'] && res['response']['status'] === 404) {
                this.service.snackbar(res['response']['result']);
                return;
              }
              if (res['response']['status'] && res['response']['status'] === 403) {
                this.service.snackbar(res['response']['result']);
                return;
              }
              if (res['response']) {
                if (res['response']['result']['user_data']) {
                  this.storageHelper.secureStorage('set', 'email', res['response']['result']['user_data']['email']);
                  this.storageHelper.secureStorage('set', 'name', res['response']['result']['user_data']['name']);
                  this.storageHelper.secureStorage('set', 'userId', res['response']['result']['user_data']['unqId']);
                  this.storageHelper.secureStorage('set', 'token', res['response']['result']['token']);
                  localStorage.setItem('jwt_token', res['response']['result']['token']);
                }
                this.service.snackbar('Login successful!');
                this.service.loggedIn = true;
                // this.storageChange();
                // window.addEventListener('storage', (event) => {
                //   if (event.storageArea == localStorage) {
                //     let token = localStorage.getItem('jwt_token');
                //     // let token = this.storageHelper.secureStorage('get', 'token');
                //     console.log('token', token);
                //     this.service.localstorageCleared =true;
                //     if (token == undefined|| !token) {
                //       // Perform logout
                //       //Navigate to login/home
                //       this.openAlertDialog()
                //     }
                //   }
                // });
                this.router.navigate(['/board']);
              }
            }, (err) => {
              this.apiInprogress = false;
            });
          }
          break;
        }
        case 'forgotPassword': {
          console.log('forgotPassword');
          if (this.apiInprogress) {
            return;
          }
          this.apiInprogress = true;
          payload = this.helper.mapForgotpasswordformDataToService(formData['data']);
          if (payload) {
            this.service.forgotPassword(payload).subscribe(res => {
              console.log('res');
              if (res) {
                this.apiInprogress = false;
                if (res['response']['status'] && res['response']['status'] === 404) {
                  this.service.snackbar(res['response']['result']);
                  return;
                }
                if (res['response']['status'] && res['response']['status'] === 403) {
                  this.service.snackbar(res['response']['result']);
                  return;
                }
                this.openLogin = false;
                this.service.snackbar('Reset initiated!');
              }
            }, err => {
              this.apiInprogress = false;
            });
          }
          break;
        };
        case 'resetPassword': {
          if (this.apiInprogress) {
            return;
          }
          this.apiInprogress = true;
          payload = this.helper.mapResetpasswordformDataToService(formData['data']);
          console.log('resetPassword', payload);
          if (payload) {
            this.service.resetPassword(payload).subscribe(res => {
              console.log('res');
              if (res) {
                this.apiInprogress = false;
                if (res['response']['status'] && res['response']['status'] === 404) {
                  this.service.snackbar(res['response']['result']);
                  return;
                }
                if (res['response']['status'] && res['response']['status'] === 403) {
                  this.service.snackbar(res['response']['result']);
                  return;
                }
                this.service.snackbar('Password reset successfully!');
              }
            }, err => {
              this.apiInprogress = false;
            });
          }
          break;
        }
      }
    }
    console.log({ payload });

  }

  verifyCode(payload) {
    const data = {
      name: 'apVerifyCode',
      param: {
        code: payload,
        email: this.actionData
      }
    };

    if (this.apiInprogress) {
      return;
    }

    this.service.verifyEmail(data).subscribe(res => {      

      if (res) {
        console.log(res);

        // unlock apiInprogress
        this.apiInprogress = false;

        if (res['response']['status'] && res['response']['status'] === 404) {
          this.service.snackbar(res['response']['result']);
          return;
        }
        if (res['response']['status'] && res['response']['status'] === 'SUCCESS') {
          this.service.snackbar("User has been verified");
          return;
        }
      }
    }, err => {
      this.apiInprogress = false;
    });
  }


  registerBtnClick() {
    this.openVerify ? this.openRegister = false: this.openRegister = !this.openRegister;
    this.openLogin = false;
  }

  forgotPassword() {

  }


  // storageChange() {
  //   this.storageListerner = window.addEventListener('storage', (event) => {
  //     if (event.storageArea == localStorage) {
  //       let token = this.storageHelper.secureStorage('get', 'token');
  //       if (token == undefined || !token) {
  //         this.openAlertDialog();
  //       }
  //     }
  //   });
  // }


  // openAlertDialog() {
  //   if (this.dialogRef) {
  //     return;
  //   }
  //   this.dialogRef = this.dialog.open(ConfirmationPopupComponent, {
  //     width: '400px',
  //     height: '240px'
  //   });

  //   this.dialogRef.afterClosed().subscribe(res => {
  //     this.dialog.closeAll();
  //     if (this.storageListerner) {
  //       this.storageListerner.unsubscribe();
  //     }
  //     this.router.navigate(['/home']);
  //   });
  // }

}
