import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';
@Component({
  selector: 'app-r-fill-in-the-blanks',
  templateUrl: './r-fill-in-the-blanks.component.html',
  styleUrls: ['./r-fill-in-the-blanks.component.scss']
})
export class RFillInTheBlanksComponent implements OnInit {
  _data;
  transformData;
  startcounter = true;
  binding;
  valid: boolean;
  apiquestionList: any;
  questionsIndexList: any[];
  _BackUpdata: any;
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this.transform(val);
    this._data = val;
  }
  @Output() back = new EventEmitter();
  score: any = 0;
  recommendtime: any = 0;
  showans: boolean;
  questionIndex = 0;
  statement;
  questionblkActive;
  questions = [];
  fillInTheBlkRLabel = {
    title: 'Sample Question - Fill in the blanks (Reading)',
    subtitle: 'In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text',
    btn: 'CHECK ANSWER',
  }
  changeQuestionList;
  isError = [];
  isSuccess = [];
  constructor(public domsanitize: DomSanitizer, public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  changeQuestion(i) {
    if (this.questionIndex === (i ? i.id - 1 : i)) {
      return;
    }
    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    
    if (this._data.questions[this.questionIndex]) {
      this.transform(this._data);
      this.isError.map((e, i) => {
        this.isError[i] = false;
      })
      this.isSuccess.map((e, i) => {
        this.isSuccess[i] = false;
      })
    }
    this.showans = false;
    this.startcounter = false;
    this.startcounter = true;
  }

  convertNewlinesToBreaks(text: string): SafeHtml {
    if (!text) {
      return '';
    }
    const replacedText = text.replace(/\n/g, '<br>');
    return this.domsanitize.bypassSecurityTrustHtml(replacedText);
  }

  replaceLineBreaks(statement: string): string {
    return statement.replace(/\n/g, '<br>');
  }

  transform(val) {
    if (val && val.questions) {
      this.changeQuestionList = false;
      this.transformData = val.questions[this.questionIndex];
      console.log("joe",this.transformData);
      // this.transformData = { ...val.questions[this.questionIndex] };
      this.recommendtime = this.transformData.question ? this.transformData.question.recommendtime : 0;
      this.service.dragAndDropOptions = [];
      this.service.dragAndDropOptions = JSON.parse(JSON.stringify(this.transformData.question ?
        this.transformData.question.options : []));
      console.log(this.transformData, this.service.dragAndDropOptions);
      setTimeout(() => {
        this.changeQuestionList = true;
      })
      this.updateDragDropTemplate();
    }
  }

  updateDragDropTemplate() {
    const searchTerm = 'response}}';
    const newArray = [];
    let replacedCount = 0;
    const tArray = this.transformData.question ?
      this.transformData.question.statement ? this.transformData.question.statement.split('{{') : [] : [];
    tArray.forEach((el, i) => {
      if (el.match(searchTerm)) {
        newArray[i] = tArray[i].replace(searchTerm, '<app-drag-box></app-drag-box>');
        replacedCount += 1;
      } else {
        newArray[i] = el;
      }
    });
    const statement = newArray.join('');
    this.transformData.question['statement'] = statement;
    // this.transformData.question['statement'] = JSON.parse(JSON.stringify(statement));
    console.log(this._data, this.transformData);

    if (this._data && this._data.questions[this.questionIndex]['isTouched']) {
      console.log('ans', this._data.questions[this.questionIndex]['userAns']);
    }
  }

  getCount(e) {

  }

  checkAns() {
    let score = 0;
    this._data.questions[this.questionIndex]['isTouched'] = true;
    const dropOptions = document.getElementsByClassName('drag-box');
    if (dropOptions) {
      for (let i = 0; i < dropOptions.length; i++) {
        if (dropOptions[i].children && dropOptions[i].children.length > 0) {
          this.valid = true;
          console.log(dropOptions[i].children[0]['innerText']);
          this._data.questions[this.questionIndex]['userAns'].push(dropOptions[i].children[0].innerHTML);
          if (dropOptions[i].children[0]['innerText'] === this.transformData.answer[i]) {
            dropOptions[i].classList.add('right-box');
            dropOptions[i].setAttribute("data-alt-pseudo", (i + 1).toString());
            score += 1;
          } else {
            dropOptions[i].classList.add('wrong-box');
            dropOptions[i].setAttribute("data-alt-pseudo", (i + 1).toString());
          }
        } else {
          this._data.questions[this.questionIndex]['userAns'].push('')
          dropOptions[i].classList.add('wrong-box');
          dropOptions[i].setAttribute("data-alt-pseudo", (i + 1).toString());
        }
      }
    }
    if (!this.valid) {
      return;
    }
    this.transformData.score = (score <= 0) ? 0 : score;
    this.score = (score <= 0) ? 0 : score;
    this.showans = true;
    this.startcounter = false;
    if (this.score  == this.transformData.answer['length']) {
      this.isSuccess[this.questionIndex] = true;
      return;
    }
    if (this.score !== this.transformData.answer['length']) {
      this.isError[this.questionIndex] = true;
    }
    console.log(score);
  }

  drop(ev) {
    ev.preventDefault();
    console.log(ev);
    const data = ev.dataTransfer.getData('text');
    ev.target.appendChild(document.getElementById(data));
  }

  drag(ev) {
    ev.dataTransfer.setData('text', ev.target.id);
  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  filter(e) {
    if (this.questions && e['check']) {
      this.questions = [...data.questions];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions);
    } else {
      this.questions = [...data.questions];
    }
  }


  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 'drag_and_drop';
          });
          this.apiquestionList = questionList[0];
          console.log({ questionList });
          const questions = [...questionList];
          console.log('questions', questions);
          this._data = JSON.parse(JSON.stringify(questions[0]));
          console.log('data....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          this._data.questions.map(e => {
            return e['userAns'] = []
          })
          this._BackUpdata = JSON.parse(JSON.stringify(questions[0]));
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.isError.push(false);
            this.isSuccess.push(false);
            this.questionsIndexList.push(template);
          });
          this.questions = [...this.questionsIndexList];
          console.log('this.questions', this.questions);
          this.transform(this._data);
        }
      }
    });
  }


  reset() {
    if (this._data.questions[this.questionIndex]['isTouched']) {
      // this._data.questions[this.questionIndex] = JSON.parse(JSON.stringify(this._BackUpdata.questions[this.questionIndex]))
      console.log('test', this._data.questions[this.questionIndex], this._BackUpdata.questions[this.questionIndex])
      // this.transformData = {};
      setTimeout(() => {

        const dropOptions = document.getElementsByClassName('drag-box');
        if (dropOptions) {
          for (let i = 0; i < dropOptions.length; i++) {
            if (dropOptions[i].children && dropOptions[i].children.length > 0) {
              dropOptions[i].classList.remove('right-box')
              dropOptions[i].classList.remove('wrong-box')
            } else {
              dropOptions[i].classList.remove('wrong-box');
              dropOptions[i].removeAttribute("data-alt-pseudo");
            }
          }
        }

        // this._data.questions[this.questionIndex]['userAns'] = []
        this.showans = false;
        this.startcounter = false;
        this.startcounter = true;
      })
      this.isError[this.questionIndex] = false;
      this.isSuccess[this.questionIndex] = false;
      console.log('reset');
    }
  }

  allowDrop(ev) {
    this.reset();
    ev.preventDefault();
  }

}
