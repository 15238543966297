<div class="col-lg-12 mct-3 px-5 pxc-5 py-4 dashboard">
  <div class="col-lg-12 px-5 pxc-5">
    <div class="col-lg-12">
      <div class="card px-lg-5 py-4">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-1 my-auto col-6">
              <a id="back" (click)="back.emit()" class="back-action px-0 py-3"><img src="./assets/icons/arrow-back.svg"
                  width="10px" class="arrow-back" alt="arrow-back" id="arrow-back-icon"> <span class="pl-2">Back</span>
              </a>
            </div>
            <div class="col-lg-10 my-auto">
              <div class="title-blk text-center px-3 pxc-5 py-2 col-lg-12 px-0">
                <h1 class="mb-0" id="title{{data?.id}}">{{essayLabel?.title}}</h1>
              </div>
            </div>
            <div class="col-6 my-auto text-right col-lg-1">
              <span class="text-right">{{questionIndex+1}} of {{_data?.questions?.length}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 pt-2 pb-3 px-lg-0">
          <hr class="mx-3" />
          <div class="col-lg-12 pxc-5 pb-3 content-blk">
            <div class="col-lg-12 pb-3 px-0 mr-auto">
              <p class="subtitle-text" id="subtitle{{data?.id}}">{{essayLabel?.subtitle}}</p>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-10 pt-1 pl-0 {{ !questionblkActive ? 'fadeIn' : '' }}" *ngIf="!questionblkActive">
                  <div class="fill-container" [innerHTML]="transformData?.question?.statement | safe:'html'"></div>
                  <div class="col-lg-12 px-0 pt-4">
                    <app-write-box [index]="questionIndex" [wordCountVal]="wordCount[questionIndex]" [length]="qlength"
                      [answer]="answer[questionIndex]" (wordCountEvt)="wordCountEvt($event)"
                      [maxWordCount]="limit || transformData?.question?.limit"></app-write-box>
                  </div>
                </div>
                <div class="{{questionblkActive? 'col-lg-12':'col-lg-2 p-0'}}">
                  <app-question-list (questionblkActive)="questionblkActiveAction($event)" [questions]="questions"
                    (filter)="filter($event)" (action)="changeQuestion($event)" [activequestion]="questionIndex">
                  </app-question-list>
                </div>
                <div class="col-lg-12 p-0">
                  <div class="row pt-4">
                    <div class="col-lg-7 pr-4">
                    </div>
                    <div class="col-lg-3 px-2 ml-auto my-auto">
                      <div class="col-lg-9 px-2 ml-auto text-right">
                        <button class="btn ml-auto w-100  btn-primary" (click)="checkAns()" id="btn">Check
                          Answer</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-10 pt-5 pl-0 pb-4" *ngIf="showans">
                  <app-correct-answer [answerlist]="transformData?.question?.sampleanswer" [template]="'summary'">
                  </app-correct-answer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
