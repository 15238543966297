import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CanComponentDeactivate, DeactivateGuard } from 'src/app/providers/can-deactivate/can-deactivate.guard';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';
@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.scss'],
  providers: [DeactivateGuard]
})
export class ReorderComponent implements OnInit, CanComponentDeactivate {
  activeSourceIndex = null;
  activeTargetIndex = null;
  counter = true;
  questions = [];
  _data;
  @Input() data;
  @Output() back = new EventEmitter();
  @Output() right = new EventEmitter();
  @Output() left = new EventEmitter();
  @ViewChildren('targetlist', { read: ElementRef }) targetlist: QueryList<any>;
  score: any = 0;
  showans: boolean = false;
  questionIndex = 0;
  counterval = 0;
  selectedIndex: any;
  questionblkActive: any;
  reorderLabel = {
    title: 'Sample Question - Re-order paragraphs (Reading)',
    subtitle: 'The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.',
    btn: 'CHECK ANSWER',
  }
  apiquestionList: any;
  questionsIndexList: any[];
  isError = [];
  pause: boolean;
  isSuccess = [];
  constructor(public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  drop(event: CdkDragDrop<[]>) {
    this.reset();
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      console.log(event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex);

      transferArrayItem(
        event.container.data,
        event.previousContainer.data,
        ((event.currentIndex === -1) ? event.currentIndex + 2 : event.currentIndex + 1),
        event.previousIndex);

      console.log(event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex);
    }
    this.activeSourceIndex = null;
    this.activeTargetIndex = null;
    this.isError[this.questionIndex] = false;
    this.isSuccess[this.questionIndex] = false;
  }

  pushleft() {
    this.reset();
    console.log(this.activeTargetIndex, this._data.questions[this.questionIndex].target[this.activeTargetIndex]);
    if (this.activeTargetIndex != null && this._data.questions[this.questionIndex].target[this.activeTargetIndex]) {
      const sourceData = this._data.questions[this.questionIndex].target[this.activeTargetIndex];
      this._data.questions[this.questionIndex].source.map((element, index) => {
        if (element === '' && !this._data.questions[this.questionIndex].source.some(e => e === sourceData)) {
          this._data.questions[this.questionIndex].target[this.activeTargetIndex] = '';
          return this._data.questions[this.questionIndex].source[index] = sourceData;
        } else {
          return element;
        }
      });
      this.activeTargetIndex = null;
      this.isError[this.questionIndex] = false;
      this.isSuccess[this.questionIndex] = false;
    }
  }

  pushright() {
    this.reset();
    console.log(this.activeSourceIndex);
    if (this.activeSourceIndex != null && this._data.questions[this.questionIndex].source[this.activeSourceIndex]) {
      const sourceData = this._data.questions[this.questionIndex].source[this.activeSourceIndex];
      this._data.questions[this.questionIndex].target.forEach((element, index) => {
        if (element == '' && !this._data.questions[this.questionIndex].target.some(e => e == sourceData)) {
          this._data.questions[this.questionIndex].source[this.activeSourceIndex] = '';
          return this._data.questions[this.questionIndex].target[index] = sourceData;
        } else {
          return element;
        }
      });
      console.log('this._data.questions[this.questionIndex].target', sourceData, this._data.questions[this.questionIndex].target);
      this.activeSourceIndex = null;
      this.isError[this.questionIndex] = false;
      this.isSuccess[this.questionIndex] = false;
    }
  }

  pushTop() {
    this.reset();
    console.log(this.activeTargetIndex, this._data.questions[this.questionIndex].target[this.activeTargetIndex]);
    if (this.activeTargetIndex != null && this._data.questions[this.questionIndex].target[this.activeTargetIndex]) {
      if (this.activeTargetIndex !== 0) {
        const currentData = { ...this._data.questions[this.questionIndex].target[this.activeTargetIndex] };
        this._data.questions[this.questionIndex].target[this.activeTargetIndex] = this._data.questions[this.questionIndex].target[this.activeTargetIndex - 1];
        this._data.questions[this.questionIndex].target[this.activeTargetIndex - 1] = currentData;
      }
      this.activeTargetIndex = null;
      this.isError[this.questionIndex] = false;
      this.isSuccess[this.questionIndex] = false;
    }
  }

  pushBottom() {
    this.reset();
    console.log(this.activeTargetIndex, this._data.questions[this.questionIndex].target[this.activeTargetIndex]);
    if (this.activeTargetIndex != null && this._data.questions[this.questionIndex].target[this.activeTargetIndex]) {
      if (this.activeTargetIndex !== (this._data.questions[this.questionIndex].target.length - 1)) {
        const currentData = { ...this._data.questions[this.questionIndex].target[this.activeTargetIndex] };
        this._data.questions[this.questionIndex].target[this.activeTargetIndex] = this._data.questions[this.questionIndex].target[this.activeTargetIndex + 1];
        this._data.questions[this.questionIndex].target[this.activeTargetIndex + 1] = currentData;
      }
      this.activeTargetIndex = null;
      this.isError[this.questionIndex] = false;
      this.isSuccess[this.questionIndex] = false;
    }
  }

  getCount(val) {
    console.log(val);
    console.log();
    this._data.questions[this.questionIndex].timer = val;
    this.showans = true;
    const score = this.getScore(this._data.questions[this.questionIndex].result, this._data.questions[this.questionIndex].target);
    this.score = (score < 0) ? 0 : score;
    if (!this._data.questions[this.questionIndex].target.some(e => e !== '')) {
      return;
    }
    if (this.targetlist.toArray()) {
      const list = this.targetlist.toArray();
      console.log('list---------------', list);
      list.forEach((el, i) => {
        if (el.nativeElement) {
          if (i !== list.length - 1) {
            if (this._data.questions[this.questionIndex].result.some(c => c === list[i].nativeElement.id + list[i + 1].nativeElement.id)) {
              list[i].nativeElement.classList.remove('wrong-ans');
              list[i + 1].nativeElement.classList.remove('wrong-ans');
              list[i].nativeElement.classList.add('right-ans');
              list[i + 1].nativeElement.classList.add('right-ans');
            } else {
              if (list[i].nativeElement.classList && !list[i].nativeElement.classList.value.includes('right-ans')) {
                list[i].nativeElement.classList.add('wrong-ans');
              }
              if (list[i + 1].nativeElement.classList && !list[i + 1].nativeElement.classList.value.includes('right-ans')) {
                list[i + 1].nativeElement.classList.add('wrong-ans');
              }
            }
          }
        }
      });

      console.log('this.score --', this.score, this._data.questions[this.questionIndex].result['length'])
      setTimeout(() => {
        if (+this.score == +this._data.questions[this.questionIndex].result['length']) {
          this.isError[this.questionIndex] = false;
          this.isSuccess[this.questionIndex] = true;
        } else {
          this.isSuccess[this.questionIndex] = false;
          this.isError[this.questionIndex] = true;
        }
      })
    }
  }

  checkAns() {
    if (this._data.questions[this.questionIndex].target.some(el => el !== '')) {
      this.counter = false;
      this.showans = true;
      this._data.questions[this.questionIndex]['isTouched'] = true;
    }
  }

  getComb(area) {
    const comb = [];
    for (let i = 0; i < area.length - 1; i++) {
      const sample = area[i].id + area[i + 1].id;
      comb.push(sample);
    }
    return comb;
  }

  getScore(ans, questionArr) {
    console.log({ ans, questionArr })
    const rghtAns = this.getComb(questionArr);
    const finalAns = ans;
    let score = 0;
    rghtAns.forEach(el => {
      if (finalAns.some(e => e === el)) {
        score++;
      }
      console.log({ score });
    });

    return score;
  }

  changeQuestion(i) {
    this.score = 0;
    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    this.pause = false;
    console.log('this._data.questions[this.questionIndex]', this._data.questions[this.questionIndex]);
    if (this._data.questions[this.questionIndex]['isTouched']) {
      this.reset();
      setTimeout(() => {
        this.getCount(this._data.questions[this.questionIndex].timer)
      })
      this.pause = true;
      console.log('timer', this._data.questions[this.questionIndex]['timer']);
      this.counterval = this._data.questions[this.questionIndex]['timer'];
      return;
    } else {
      console.log(i, this._data);
      this.counterval = 0;
      this.reset();
    }
  }

  reset() {
    const list = this.targetlist.toArray();
    list.forEach((el, i) => {
      if (el.nativeElement) {
        console.log(el.nativeElement.id);
        list[i].nativeElement.classList.remove('wrong-ans');
        list[i].nativeElement.classList.remove('right-ans');
      }
    });
    this.pause = false;
    this.counterval = 0;
    this.score = 0;
    this.counter = true;
    this.showans = false;
  }

  setFlag(questionsList) {
    this.questions = questionsList;
    console.log(this.questions);
  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  filter(e) {
    if (this.questions && e && e['check']) {
      console.log('questionsIndexList', this.questions, e['check'], this.questionsIndexList);
      this.questions = [...this.questionsIndexList];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions, this.questions);
    } else {
      this.questions = [...this.questionsIndexList];
    }
    console.log('questions', this.questions);
  }

  updateReorderData(reorderData) {
    if (reorderData.questions) {
      reorderData.questions.map((el) => {
        el['result'] = this.getComb(el.answer);
        el['source'] = this.shuffleArray(el.answer);
        el['target'] = this.getEmptyTargetArr(el.answer);
        el['isTouched'] = false;
        return el['result'];
      });
    }
    console.log('reorderData -- mapped', reorderData);
    return reorderData;
  }

  getEmptyTargetArr(data) {
    const target = [];
    if (data) {
      data.forEach(e => {
        target.push('');
      });
      return target;
    }
  }

  shuffleArray(array) {
    const shuffleArr = [...array];
    for (let i = shuffleArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = shuffleArr[i];
      shuffleArr[i] = shuffleArr[j];
      shuffleArr[j] = temp;
    }
    if (JSON.stringify(shuffleArr) === JSON.stringify(array)) {
      console.log('true');
      this.shuffleArray(array);
    } else {
      const indexedArray = shuffleArr.map((item, index) => ({
        ...item,
        data: `${index + 1}) ${item.data}`
      }));
      console.log('joe1', array);
      return indexedArray;
    }
  }

  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 're_order_paragraph';
          });
          this.apiquestionList = questionList;
          console.log({ questionList });
          // const questions = [...questionList];
          const questions = questionList;
          console.log('questions', questions);
          this._data = questions[0];
          // this._data = JSON.parse(JSON.stringify(questions[0]));
          this._data = this.updateReorderData(this._data);
          console.log('joedata....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.isError.push(false);
            this.isSuccess.push(false);
            this.questionsIndexList.push(template);
          });
          this.questions = this.questionsIndexList;
          // this.questions = [...this.questionsIndexList];
          console.log('this.questions', this.questions);
        }
      }
    });
  }

  getIndex(e) {
    console.log('active -index', e);
    this.selectedIndex = e;
  }

  confirm() {
    return true;
  }
}
