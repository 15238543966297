<mat-dialog-content>
  <div class="col text-center p-1">
    <p class="p-2 ptext-white title mb-0">Please sign in again</p>
  </div>
  <p class="p-4 text-center message mb-0">
    You were signed out of your account.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col text-center">
    <div class="row">
      <div class="col-6 mx-auto pl-lg-0 pr-lg-0">
        <button type="button" id="yes" class="btn btn-default btn-lg center-block w-100" (click)="onConfirmClick(true)" >
          ok
        </button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
