import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as data from './footer.data';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address = data.address;
  footerLinks = data.footerLinks;
  footerForm: FormGroup;
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {
    this.footerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  submit() {
    if(this.footerForm.valid){
      console.log(this.footerForm.value);
    }
  }
}
