import { Component, ComponentFactoryResolver, HostListener, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { JppService } from './providers/jpp.service';
import { DragBoxComponent } from './shared/components/drag-box/drag-box.component';
import { createCustomElement } from '@angular/elements';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from './providers/confirmation-popup/confirmation-popup.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [JppService]
})
export class AppComponent {
  title = 'jpp-exam-application';
  @ViewChild('popup', { read: ViewContainerRef }) popup: ViewContainerRef;
  componentRef;
  dialogRef: any;
  @HostListener('document:contextmenu', ['$event'])
  disableRight(){
    if(this.router.url.includes('/home')){
      return true;
    } else {
      return false;
    }
  }


  constructor(
    public service: JppService,
    public injector: Injector,
    public router: Router,
    public componentResolver: ComponentFactoryResolver,
    public dialog: MatDialog) {
    const ngElement = createCustomElement(DragBoxComponent, {
      injector: this.injector
    });
    customElements.define('app-drag-box', ngElement);
  }

  ngOnInit() {
    const bc = new BroadcastChannel('test_channel');
    bc.onmessage = (ev) => {
      console.log('broad-cast', ev);
      if (ev.data == "logout" && !this.router.url.includes('home')) {
        this.openAlertDialog()
      }
    }
  }

  openAlertDialog() {
    if (this.dialogRef) {
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      height: '240px',
      data: {
        title: 'Please sign in again',
        message: 'You were signed out of your account.',
        enableBtn: true,
        enableIcon: false,
        buttonText: {
          ok: 'Ok',
          cancel: ''
        }
      }
    });
  }

  onRightClick() {
    if(this.router.url.includes('/home')){
      return true;
    } else {
      return false;
    }
  }
}
