import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class JppService {
  public loggedIn = true;
  public enableBtn = true;
  public enableIcon = true;
  localstorageCleared = false;
  public dragAndDropOptions = [];
 public writingAnswer = '';
  dialogMessage='';
  dialogTitle='';
  isError=false;
  public wordCount =  new BehaviorSubject(0);
  headers = {
    headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
  };

  jppServiceUrl = environment.jppServiceUrl;
  constructor(private http: HttpClient) { }

  register(payload) {
    return this.http.post(this.jppServiceUrl,
      {
        name: 'apSignup',
        param: payload
      });
  }

  login(payload) {
    return this.http.post(this.jppServiceUrl,
      {
        name: 'apSignin',
        param: payload
      });
  }

  forgotPassword(payload) {
    return this.http.post(this.jppServiceUrl,
      {
        name: 'apForgotpswd',
        param: payload
      });
  }

  resetPassword(payload) {
    return this.http.post(this.jppServiceUrl,
      {
        name: 'apResetpswd',
        param: payload
      });
  }

  verifyEmail(payload) {
    return this.http.post(this.jppServiceUrl, payload);
  }

  reOrderParagraph(payload) {
    return this.http.post(this.jppServiceUrl + 'PTE_ins_reorderpara.php', payload);
  }

  getExamList() {
    return this.http.post(this.jppServiceUrl, {
      name: 'examsList',
      param: {
        page: 1,
        limit: 100
      }
    });
  }

  snackbar(data, duration?) {
    const x = document.getElementById('snackbar');
    x.className = 'show';
    x.innerText = data;

    // After 3 seconds, remove the show class from DIV
    setTimeout(() => { x.className = x.className.replace('show', ''); }, duration || 3000);
  }
}
