import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as data from '../../data/data';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {
  _questions = [];
  @Input()
  set questions(val) {
    if (val['length'] <= 10) {
      this.calcWidth = (100 / val['length']) ? (100 / val['length']) : 10;
    } else {
      this.calcWidth = 10;
    }
    console.log('calcWidth', this.calcWidth);
    this._questions = val;
  }
  @Input() activequestion = 0;
  @Output() filter = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() questionblkActive = new EventEmitter();
  @Output() updateQuestions = new EventEmitter();
  selectedIndex;
  activeIndex = 0;
  enableListView = false;
  applyFilter = [];
  calcWidth;
  constructor() { }

  ngOnInit(): void {
  }

  setFlag(flag) {
    console.log(this.selectedIndex, typeof this.selectedIndex, 'flag');
    if ((this.selectedIndex !== 'undefined') && (this.selectedIndex === 0 || this.selectedIndex)) {
      if (this.selectedIndex == this.activeIndex) {
        return;
      }
      this._questions[this.selectedIndex].flag = flag;
    }
    this.updateQuestions.emit(this._questions);
  }

  applyFilterAction(flagclr, i) {
    this.applyFilter[i] = !this.applyFilter[i];
    this.applyFilter.map((el, index) => {
      if (index !== i) {
        return this.applyFilter[index] = false;
      }
    });
    this.filter.emit({ flag: flagclr, 'check': this.applyFilter[i] });
  }

  nextAction() {
    if (this.activeIndex >= 0 && this.activeIndex < this._questions.length - 1) {
      this.activeIndex += 1;
      this.action.emit(this._questions[this.activeIndex]);
    }
  }

  prevAction() {
    if (this.activeIndex > 0 && this.activeIndex < this._questions.length) {
      this.activeIndex -= 1;
      this.action.emit(this._questions[this.activeIndex]);
    }
  }

  resetFilter(e) {
    this.applyFilter.map((el, index) => {
      return this.applyFilter[index] = false;
    });
    this.selectedIndex = null;
  }

}
