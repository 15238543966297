import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';

@Component({
  selector: 'app-essay',
  templateUrl: './essay.component.html',
  styleUrls: ['./essay.component.scss']
})
export class EssayComponent implements OnInit {
  _data;
  transformData;
  startcounter = true;
  binding;
  valid: boolean;
  apiquestionList: any;
  questionsIndexList: any[];
  qlength: any;
  wordCount = [];
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this.transform(val);
    this._data = val;
  }
  @Output() back = new EventEmitter();
  score: any = 0;
  recommendtime: any = 0;
  showans: boolean;
  questionIndex = 0;
  statement;
  questionblkActive;
  questions = [];
  limit = 300;
  answer = [];
  essayLabel = {
    title: 'Sample Question - Essay (Writing)',
    subtitle: `You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200 words.`,
  }
  changeQuestionList;
  constructor(public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
    this.essayLabel['subtitle'] = `You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write ${this.limit || '200'} words.`
  }

  changeQuestion(i) {
    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    console.log('{questionIndex}', this.questionIndex, this._data);
    if (this._data.questions[this.questionIndex]) {
      this.transform(this._data);
      this.service.writingAnswer = this.answer[this.questionIndex]
    }
    this.showans = false;
    this.startcounter = false;
    this.startcounter = true;
    this.answer[this.questionIndex] = '';
  }

  transform(val) {
    console.log('val', val);
    if (val && val.questions) {
      this.changeQuestionList = false;
      this.transformData = { ...val.questions[this.questionIndex] };
      console.log('transformData', this.transformData);
      console.log(this.transformData);
    }
  }

  getCount(e) {

  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  filter(e) {
    if (this.questions && e['check']) {
      this.questions = [...data.questions];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions);
    } else {
      this.questions = [...data.questions];
    }
  }


  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 'essay';
          });
          this.apiquestionList = questionList[0];
          console.log({ questionList });
          const questions = [...questionList];
          console.log('questions', questions);
          this._data = JSON.parse(JSON.stringify(questions[0]));
          console.log('data....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.questionsIndexList.push(template);
            this.answer.push('');
          });
          this.qlength = questionList[0].questions['length'];
          this.questions = [...this.questionsIndexList];
          console.log('this.questions', this.questions);
          this.transform(this._data);
        }
      }
    });
  }

  wordCountEvt(e) {
    this.wordCount[this.questionIndex] = e;
  }

  checkAns() {
    this.showans = true;
  }
}
