import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { JppService } from 'src/app/providers/jpp.service';
import * as data from '../../data/data';

@Component({
  selector: 'app-summarise',
  templateUrl: './summarise.component.html',
  styleUrls: ['./summarise.component.scss']
})
export class SummariseComponent implements OnInit {
  _data;
  transformData;
  startcounter = true;
  binding;
  valid: boolean;
  apiquestionList: any;
  questionsIndexList: any[];
  qlength: any;
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this.transform(val);
    this._data = val;
  }
  @Output() back = new EventEmitter();
  score: any = 0;
  recommendtime: any = 0;
  showans: boolean;
  questionIndex = 0;
  statement;
  questionblkActive;
  questions = [];
  limit = 75;
  safelimt = 75;
  essayLabel = {
    title: 'Sample Question - Summarise Written Text (Writing)',
    subtitle: 'Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.',
  }
  changeQuestionList;
  answer=[];
  wordCount=[];
  constructor(public service: JppService) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  changeQuestion(i) {
    if (i) {
      this.questionIndex = i ? i.id - 1 : i;
    }
    console.log('{questionIndex}', this.questionIndex, this._data);
    if (this._data.questions[this.questionIndex]) {
      this.transform(this._data);
    }
    this.showans = false;
    this.startcounter = false;
    this.startcounter = true;
    this.answer[this.questionIndex] = '';
  }

  transform(val) {
    console.log('val', val);
    if (val && val.questions) {
      this.changeQuestionList = false;
      this.transformData = { ...val.questions[this.questionIndex] };
      console.log('transformData', this.transformData);
      console.log(this.transformData);
    }
  }

  getCount(e) {

  }

  questionblkActiveAction(e) {
    this.questionblkActive = e;
  }

  replaceLineBreaks(statement: string): string {
    return statement.replace(/\n/g, '<br>');
  }

  filter(e) {
    if (this.questions && e['check']) {
      this.questions = [...data.questions];
      const filteredQuestions = this.questions.filter(el => {
        return el['flag'] === e['flag'];
      });
      this.questions = filteredQuestions;
      console.log(e, filteredQuestions);
    } else {
      this.questions = [...data.questions];
    }
  }


  getQuestions() {
    this.service.getExamList().subscribe((res: any) => {
      if (res['response']) {
        console.log(res);
        if (res['response']['result']) {
          const questionList = res['response']['result'].filter(el => {
            return el['type'] === 'summarise';
          });
          this.apiquestionList = questionList[0];
          console.log({ questionList });
          const questions = [...questionList];
          console.log('questions', questions);
          this._data = JSON.parse(JSON.stringify(questions[0]));
          console.log('data....', this._data);
          this.questionsIndexList = [];
          this.questions = [];
          questionList[0].questions.forEach((element, i) => {
            const template = {
              name: `Question ${i + 1}`,
              id: i + 1,
              disabled: false,
              flag: ''
            }
            this.answer[i] ='';
            this.questionsIndexList.push(template);
          });
          this.qlength = questionList[0].questions['length'];
          console.log('length', this.qlength, questionList[0].questions);
          this.questions = [...this.questionsIndexList];
          console.log('this.questions', this.questions);
          this.transform(this._data);
        }
      }
    });
  }

  wordCountEvt(e){
    this.wordCount[this.questionIndex] =e;
  }

  checkAns() {
    this.showans = true;
  }

  reset(){
    if(this.showans){
      this.showans = false;
    }
  }

}
