<div [class]="enableModal ? 'modal show' :''" tabindex="-1" role="dialog">
  <div [class]="enableModal ? 'modal-dialog modal-lg' : ''" role="document">
    <div [class]="enableModal ?'modal-content' :''">
      <form [formGroup]="onbardForm" autocomplete="off">
        <ng-container [ngSwitch]="options">
          <ng-container *ngSwitchDefault>
            <div class="login-container" formGroupName="login" id="login-form">
              <span class="close-icon" (click)="close.emit()"></span>
              <div class="col-lg-12 login-card px-4 py-4">
                <div class="col-lg-12 py-3 px-1">
                  <h4 class="text-white pb-4">Start Your PTE Success Story Here</h4>
                  <div class="col-lg-12 px-2 pb-lg-3">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="email">Email or User name</label>
                          <input (keydown.enter)="loginIn()" type="email" class="form-control" id="signInEmail"
                            name="email" formControlName="email">
                          <p
                            class="error-blk {{((oFormLogin?.email?.errors?.required && oFormLogin?.email?.touched) ) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormLogin?.email?.errors?.required && oFormLogin?.email?.touched)">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="password">Password</label>
                          <input (keydown.enter)="loginIn()" type="{{showLoginPassword ? 'text' : 'password'}}"
                            class="form-control" id="signInpassword" name="password" formControlName="password">
                          <i class="fa fa-eye icon" id="showPassword" (click)="toggleLoginPassword()"
                            *ngIf="!showLoginPassword"></i>
                          <i class="fa fa-eye-slash icon" id="togglePassword" (click)="toggleLoginPassword()"
                            *ngIf="showLoginPassword"></i>
                          <p
                            class="error-blk {{(oFormLogin?.password?.errors?.required && oFormLogin?.password?.touched) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormLogin?.password?.errors?.required && oFormLogin?.password?.touched)">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 terms-blk">
                    <div class="row">
                      <div class="col-lg-6 text-lg-left text-center pb-3 pb-lg-0">
                        <div class="col-lg-12 pt-2 px-0">
                          <a class="forgot-password" id="forgot-password" (click)="options='forgotPassword'">
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                      <div class="col-lg-6 px-lg-2">
                        <div class="row">
                          <div class="col-lg-12 text-lg-right text-center pb-2 pb-lg-0">
                            <button *ngIf="!loader" type="button" id="signInBtn" class="btn btn-more"
                              (click)="loginIn()">Sign
                              in</button>
                            <button type="button" id="signInBtn" class="btn btn-more" *ngIf="loader">
                              <div class="loader"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'forgotPassword'">
            <div class="login-container" formGroupName="forgotPassword" id="login-form">
              <span class="close-icon" (click)="close.emit()"></span>
              <div class="col-lg-12 login-card px-4 py-4">
                <div class="col-lg-12 py-3 forgot-card px-1">
                  <h5 class="text-primary pb-4">forgot Password</h5>
                  <h6 class="sub-heading px-2">Reset your password</h6>
                  <p class="instruction px-2">We will email you instructions on how to reset your password.</p>
                  <div class="col-lg-12 px-2 pb-lg-3">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="email">Email or User name</label>
                          <input (keydown.enter)="forgotPassword()" type="email" class="form-control" id="fpEmail"
                            name="fpEmail" formControlName="fpEmail">
                          <p
                            class="error-blk {{(oFormForgotPassword?.fpEmail?.errors?.required && oFormForgotPassword?.fpEmail?.touched) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormForgotPassword?.fpEmail?.errors?.required && oFormForgotPassword?.fpEmail?.touched)">Cannot
                              be blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="fpMobileNumber">Mobile number</label>
                          <input (keydown.enter)="forgotPassword()" type="tel" numbersOnly class="form-control"
                            id="fpMobileNumber" maxlength="10" name="fpMobileNumber" formControlName="fpMobileNumber">
                          <p
                            class="error-blk {{(oFormForgotPassword?.fpMobileNumber?.errors?.required && oFormForgotPassword?.fpMobileNumber?.touched) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormForgotPassword?.fpMobileNumber?.errors?.required && oFormForgotPassword?.fpMobileNumber?.touched)">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 terms-blk">
                    <div class="row">
                      <div class="col-lg-6 offset-lg-6 px-lg-2">
                        <div class="row">
                          <div class="col-lg-12 text-lg-right text-center pb-2 pt-lg-0 pt-2  pb-lg-0">
                            <button type="button" id="signInBtn" *ngIf="!loader" class="btn btn-more"
                              (click)="forgotPassword()">Submit</button>
                            <button type="button" id="signInBtn" class="btn btn-more" *ngIf="loader">
                              <div class="loader"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'resetPassword'">
            <div class="login-container" formGroupName="resetPassword" id="login-form">
              <span class="close-icon" (click)="close.emit()"></span>
              <div class="col-lg-12 login-card reset-card px-4 py-4">
                <div class="col-lg-12 forgot-card py-3 px-1">
                  <h5 class="text-primary pb-4">forgot Password</h5>
                  <h6 class="sub-heading px-2">Reset your password</h6>
                  <p class="instruction px-2">We will email you instructions on how to reset your password.</p>
                  <div class="col-lg-12 px-2 pb-lg-1">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="email">Enter your verification code </label>
                          <input (keydown.enter)="resetPassword()" type="email" class="form-control"
                            id="rpverificationcode" name="rpverificationcode" formControlName="rpverificationcode">
                          <p
                            class="error-blk
                            {{(oFormResetPassword?.fpMobileNumber?.errors?.required && oFormForgotPassword?.fpMobileNumber?.touched) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormResetPassword?.fpMobileNumber?.errors?.required && oFormForgotPassword?.fpMobileNumber?.touched)">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="rpNewPassword">New password</label>
                          <input (keydown.enter)="resetPassword()" [type]="showPassword ? 'text' : 'password'"
                            class="form-control" id="rpNewPassword" name="rpNewPassword"
                            formControlName="rpNewPassword">
                          <i class="fa fa-eye icon" id="showPassword" (click)="togglePassword()"
                            *ngIf="!showPassword"></i>
                          <i class="fa fa-eye-slash icon" id="togglePassword" (click)="togglePassword()"
                            *ngIf="showPassword"></i>
                          <p
                            class="error-blk {{(oFormResetPassword?.rpNewPassword?.errors?.required && oFormForgotPassword?.rpNewPassword?.touched) ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormResetPassword?.rpNewPassword?.errors?.required && oFormForgotPassword?.rpNewPassword?.touched) ">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <label for="rpConfirmPassword">Confirm password</label>
                          <input (keydown.enter)="resetPassword()" [type]="showRsPassword ? 'text' : 'password'"
                            class="form-control" id="rpConfirmPassword" name="rpConfirmPassword"
                            formControlName="rpConfirmPassword">
                          <i class="fa fa-eye icon" id="showRsPassword" (click)="toggleRsPassword()"
                            *ngIf="!showRsPassword"></i>
                          <i class="fa fa-eye-slash icon" id="toggleRsPassword" (click)="toggleRsPassword()"
                            *ngIf="showRsPassword"></i>
                          <p
                            class="error-blk
                            {{(oFormResetPassword?.rpConfirmPassword?.errors?.required && oFormForgotPassword?.rpConfirmPassword?.touched)  ? 'mb-0':'mb-4'}}">
                            <span class="error"
                              *ngIf="(oFormResetPassword?.rpConfirmPassword?.errors?.required && oFormForgotPassword?.rpConfirmPassword?.touched) ">Cannot
                              be
                              blank.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 terms-blk">
                    <div class="row">
                      <div class="col-lg-6 offset-lg-6 px-lg-2">
                        <div class="row">
                          <div class="col-lg-12 text-lg-right text-center pb-2 pt-lg-0 pt-2  pb-lg-0">
                            <button type="button" *ngIf="!loader" id="signInBtn" class="btn btn-more"
                              (click)="resetPassword()">Submit</button>
                            <button type="button" id="signInBtn" class="btn btn-more" *ngIf="loader">
                              <div class="loader"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </form>
    </div>
  </div>
</div>