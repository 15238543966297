import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  onbardForm: FormGroup;
  enableModal;
  @Input() options;
  @Input() loader;
  @Output() signIn = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() changeForm = new EventEmitter();
  @Output() close = new EventEmitter();
  showLoginPassword: any;
  showPassword: any;
  showRsPassword: any;
  constructor(public fb: FormBuilder, private activatedRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.onbardForm = this.fb.group({
      login: this.fb.group({
        email: ['', [Validators.required]],
        password: ['', [Validators.required]]
      }),
      forgotPassword: this.fb.group({
        fpEmail: ['', [Validators.required]],
        fpMobileNumber: ['', [Validators.required]]
      }),
      resetPassword: this.fb.group({
        rpEmail: ['', [Validators.required]],
        rpverificationcode: ['', [Validators.required]],
        rpNewPassword: ['', [Validators.required]],
        rpConfirmPassword: ['', [Validators.required]]
      }, {
        validator: ConfirmedValidator('rpNewPassword', 'rpConfirmPassword')
      })
    });
    this.enableModal = (window.screen.width < 991) ? true : false;
    this.activatedRouter.queryParams.subscribe(params => {
      if (params.resetcode) {
        console.log(params);
        this.onbardForm.get('resetPassword').get('rpverificationcode').patchValue(params.resetcode);
        this.onbardForm.get('resetPassword').get('rpEmail').patchValue(params.email);
      }
    });
  }

  get oFormLogin() {
    return this.onbardForm.get('login')['controls'];
  }
  get oFormForgotPassword() {
    return this.onbardForm.get('forgotPassword')['controls'];
  }
  get oFormResetPassword() {
    return this.onbardForm.get('resetPassword')['controls'];
  }

  changeOptions() {
    this.options = 'forgotPassword';
  }

  register() {
    this.onbardForm.markAllAsTouched();
    console.log(this.onbardForm.value);
  }

  loginIn() {
    console.log('test');
    this.onbardForm.get('login').markAllAsTouched();
    if (this.onbardForm.get('login').valid) {
      const payload = this.onbardForm.value.login;
      console.log({ payload });
      const data = {
        key: 'login',
        data: payload
      }
      this.signIn.emit(data);
    }
  }

  forgotPassword() {
    console.log('test');
    this.onbardForm.get('forgotPassword').markAllAsTouched();
    if (this.onbardForm.get('forgotPassword').valid) {
      const payload = this.onbardForm.value.forgotPassword;
      console.log({ payload });
      const data = {
        key: 'forgotPassword',
        data: payload
      }
      this.signIn.emit(data);
    }
  }

  resetPassword() {
    console.log('test');
    this.onbardForm.get('resetPassword').markAllAsTouched();
    if (this.onbardForm.get('resetPassword').valid) {
      const payload = this.onbardForm.value.resetPassword;
      console.log({ payload });
      const data = {
        key: 'resetPassword',
        data: payload
      }
      console.log({ data });
      this.signIn.emit(data);
    }
  }

  toggleLoginPassword() {
    this.showLoginPassword = !this.showLoginPassword;
  }

  toggleRsPassword() {
    this.showRsPassword = !this.showRsPassword;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.passwordMatch) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
