<div class="question-blk {{!enableListView ? 'fadeIn':''}}" *ngIf="!enableListView">
  <p class="source-blk"  *ngIf="false">
    <span>
      <span class="icon-blk"
        (click)="enableListView=!enableListView;questionblkActive.emit(enableListView);filter.emit()"><img
          src="./assets/icons/list-icon.png" width="20px" id="lis-icon" /></span>
    </span>
    <span class="float-right" *ngIf="false">
      <span class="icon-blk" [ngClass]="{'active-filter':applyFilter[0]}" (click)="applyFilterAction('red', 0)"><img
          src="./assets/icons/red-flag.svg" width="20px" id="red-flag" /></span>
      <span class="icon-blk" [ngClass]="{'active-filter':applyFilter[1]}" (click)="applyFilterAction('yellow', 1)"><img
          src="./assets/icons/yellow-flag.svg" width="20px" id="yellow-flag" /></span>
      <span class="icon-blk" [ngClass]="{'active-filter':applyFilter[2]}" (click)="applyFilterAction('green', 2)"><img
          src="./assets/icons/green-flag.svg" width="20px" id="green-flag" /></span>
    </span>
  </p>
  <p class="source-blk py-3">
     Questions
  </p>
  <ul class="q-blk">
    <li [ngClass]="{ 'no-cursor': question?.disabled }" *ngFor="let question of _questions;let i=index">
      <p class="mb-0" (click)="action.emit(question);activeIndex = i"
        [ngClass]="{'active': i==activeIndex,'disabled': question?.disabled }">
        {{question?.name}}
      </p>
    </li>
  </ul>
  <div class="col-lg-12 text-right p-0 pt-2">
    <button class="btn btn-default px-3 mx-2" (click)="prevAction()">
      Previous
    </button>
    <button class="btn btn-default px-3" (click)="nextAction()">
      Next
    </button>
  </div>
</div>
<div class="col-lg-12 question-box-blk {{enableListView ? 'fadeIn':''}}" *ngIf="enableListView">
  <p class="source-q-blk">
    <span>
      <span class="list-view-active" title="list-view active"
      (click)="enableListView=!enableListView;questionblkActive.emit(enableListView);resetFilter(enableListView)"><img
          src="./assets/icons/list-icon.png" width="20px" id="red-flag" /></span>
    </span>
    <span class="float-right">
      <span class="icon-blk" (click)="setFlag('red')"><img src="./assets/icons/red-flag.svg" width="20px"
          id="red-flag" /></span>
      <span class="icon-blk" (click)="setFlag('yellow')"><img src="./assets/icons/yellow-flag.svg" width="20px"
          id="yellow-flag" /></span>
      <span class="icon-blk" (click)="setFlag('green')"><img src="./assets/icons/green-flag.svg" width="20px"
          id="green-flag" /></span>
    </span>
  </p>
  <div class="items-grid">
    <div tabindex="0" class="item-card" [style.width.%]="calcWidth" *ngFor="let question of _questions;let i=index"
    [ngClass]="{'active-question': activeIndex == i}">
      <div class="overlay" (click)="selectedIndex = i" [ngClass]="{'box-yellow': (question?.flag == 'yellow' && activeIndex !== i),
      'box-red': (question?.flag == 'red' && activeIndex !== i), 'box-green': (question?.flag == 'green' && activeIndex !== i) }">
        <div class="item-number">
          {{question?.id}}
        </div>
      </div>
    </div>
  </div>
</div>
