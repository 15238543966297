import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTileComponent } from './components/dashboard-tile/dashboard-tile.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { IntroComponent } from './components/intro/intro.component';
import { ReorderComponent } from './components/reorder/reorder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScoreAndTimerComponent } from './components/score-and-timer/score-and-timer.component';
import { QuestionListComponent } from './components/question-list/question-list.component';
import { CorrectAnswerComponent } from './components/correct-answer/correct-answer.component';
import { FillInBlanksComponent } from './components/fill-in-blanks/fill-in-blanks.component';
import { MCSingleAnsComponent } from './components/m-c-single-ans/m-c-single-ans.component';
import { MCMultipleAnsComponent } from './components/m-c-multiple-ans/m-c-multiple-ans.component';
import { SafePipe } from './pipe/safe.pipe';
import { FormsModule } from '@angular/forms';
import { RFillInTheBlanksComponent } from './components/r-fill-in-the-blanks/r-fill-in-the-blanks.component';
import { DragBoxComponent } from './components/drag-box/drag-box.component';
import { SharedDirectiveModule } from './directives/shared.directive.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { PopupComponent } from '../providers/popup/popup.component';
import { EssayComponent } from './components/essay/essay.component';
import { SummariseComponent } from './components/summarise/summarise.component';
import { WriteBoxComponent } from './components/write-box/write-box.component';
@NgModule({
  declarations: [
    DashboardTileComponent,
    DashboardCardComponent,
    IntroComponent,
    ReorderComponent,
    ScoreAndTimerComponent,
    QuestionListComponent,
    CorrectAnswerComponent,
    FillInBlanksComponent,
    MCSingleAnsComponent,
    MCMultipleAnsComponent,
    SafePipe,
    RFillInTheBlanksComponent,
    DragBoxComponent,
    PopupComponent,
    EssayComponent,
    SummariseComponent,
    WriteBoxComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedDirectiveModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    DashboardTileComponent,
    DashboardCardComponent,
    IntroComponent,
    ReorderComponent,
    ScoreAndTimerComponent,
    QuestionListComponent,
    FillInBlanksComponent,
    MCSingleAnsComponent,
    MCMultipleAnsComponent,
    RFillInTheBlanksComponent,
    EssayComponent,
    SummariseComponent,
    WriteBoxComponent,
    DragBoxComponent,
    SharedDirectiveModule,
    SafePipe,
    PopupComponent
  ],
  entryComponents: [DragBoxComponent, PopupComponent]
})
export class SharedModule { }
