<div class="col-lg-12 mct-3 px-5 pxc-5 py-4 dashboard">
  <div class="col-lg-12 px-5 pxc-5">
    <div class="col-lg-12">
      <div class="card px-lg-5 py-4">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-1 my-auto col-6">
              <a id="back" (click)="back.emit()" class="back-action px-0 py-3"><img src="./assets/icons/arrow-back.svg"
                  width="10px" class="arrow-back" alt="arrow-back" id="arrow-back-icon"> <span class="pl-2">Back</span>
              </a>
            </div>
            <div class="col-lg-10 my-auto">
              <div class="title-blk text-center px-3 pxc-5 py-2 col-lg-12 px-0">
                <h1 class="mb-0" id="title{{data?.id}}">{{fillInTheBlkRWLabel?.title}}</h1>
              </div>
            </div>
            <div class="col-6 my-auto text-right col-lg-1">
              <span class="text-right">{{questionIndex+1}} of {{_data?.questions?.length}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 pt-2 pb-3 px-lg-0">
          <hr class="mx-3" />
          <div class="col-lg-12 pxc-5 pb-5 content-blk">
            <div class="col-lg-12 pb-4 px-0 mr-auto">
              <p class="subtitle-text" id="subtitle{{data?.id}}">{{fillInTheBlkRWLabel?.subtitle}}</p>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-10 pl-0 {{!questionblkActive ? 'fadeIn':''}}" *ngIf="!questionblkActive">
                  <div class="fill-statement">
                    <div [ngClass]="{
                    'errorBlk': isError[questionIndex],
                    'successBlk': isSuccess[questionIndex],
                    'pt-4 pb-2 px-2': ( isError[questionIndex] || isSuccess[questionIndex])
                  }" [innerHTML]="transformData?.question?.statement | safe:'html'"></div>
                  </div>
                </div>
                <div class="{{questionblkActive? 'col-lg-12':'col-lg-2 px-0'}}">
                  <app-question-list (questionblkActive)="questionblkActiveAction($event)" [questions]="questions"
                    (filter)="filter($event)" (action)="changeQuestion($event)" [activequestion]="questionIndex">
                  </app-question-list>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-7 pr-4">
                <app-score-and-timer [startcounter]="startcounter" (count)="getCount($event)" [score]="score"
                  [timeRecommended]="recommendtime">
                </app-score-and-timer>
              </div>
              <div class="col-lg-3 px-2 ml-auto my-auto">
                <div class="col-lg-9 px-2 ml-auto text-right">
                  <button class="btn ml-auto w-100  btn-primary" (click)="checkAns()"
                    id="btn">{{fillInTheBlkRWLabel?.btn}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-10 pt-5 pb-4" *ngIf="showans">
            <app-correct-answer [answerlist]="transformData?.answer" [template]="'fill&blanks'"></app-correct-answer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
