<footer class="footer-area section-gap">
  <div class="col-lg-12">
    <div class="col-lg-12 px-lg-5 pb-5">
      <div class="row px-lg-5">
        <div class="col-lg-3 pt-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h4>Social Links</h4>
            <div class="col-lg-12 p-0 col-sm-12 footer-social">
              <a target="_blank" id="facebook-link" href="https://www.facebook.com/jmspte/"><i class="fa fa-facebook pr-3"></i>
              </a>
              <a target="_blank" id="twitter-link" href="https://twitter.com/jmsptetraining/"><i class="fa fa-twitter pr-3"></i>
              </a>
              <a target="_blank" id="youtube-link" href="https://www.youtube.com/channel/UCAlzAREMmDSEYBwVycU91pw"><i class="fa fa-youtube-play pr-3"></i>
              </a>
              <a target="_blank" id="instagram-link" href="https://www.instagram.com/jmspte/"><i class="fa fa-instagram pr-3"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 pt-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h4>Navigation</h4>
            <ul>
              <li *ngFor="let footer of footerLinks"><a [id]="footer?.name" [href]="footer?.link">{{footer?.name}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 pt-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h4>Address</h4>
            <ul>
              <li id="address">{{address?.lineOne}}<br />{{address?.lineTwo}}</li>
              <li><a id="email-address" href="mailTo:{{address?.email}}">{{address?.email}}</a></li>
              <li><a id="phone-number" href="tel:{{address?.phoneNumber}}">{{address?.phoneNumber}}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 pt-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h4>Newsletter</h4>
            <form [formGroup]="footerForm" autocomplete="off">
              <div class="input-group">
                <input type="text" formControlName="email" name="email" id="subscriptionMailId" class="form-control" placeholder="Enter Your Email-ID">
                <span class="input-group-btn">
                  <button class="btn btn-default" (click)="submit()" type="button"><span>Submit</span></button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 bg-black align-items-center text-center justify-content-between">
    <p class="footer-text m-0 py-2">
      &copy;2020 - All Rights Reserved. Website Designed and Developed by
      <a class="copyrightslink" id="companyLink" href="http://moonhive.in/" target="_blank">MoonHive</a>
    </p>
  </div>
</footer>
