import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageHelper } from 'src/app/shared/helper/helper';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private ls: StorageHelper) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.ls.secureStorage('get', 'token');

    if (token) {
      request = request.clone({ headers: request.headers.set('authorization', token) });
    }

    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }));
  }
}
