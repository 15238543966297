import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-popup',
  templateUrl: 'confirmation-popup.component.html',
  styleUrls: ['confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  title: string = "Are you sure?"
  message: string = ""
  confirmButtonText = "Yes"
  cancelButtonText = "";
  enableBtn = false;
  enableIcon= false;
  constructor( ) {
  }

  onConfirmClick(res): void {
    console.log('onConfirmClick', res);
    const bc = new BroadcastChannel('test_channel');
    bc.close();
    window.location.reload();
  }

}
