import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-correct-answer',
  templateUrl: './correct-answer.component.html',
  styleUrls: ['./correct-answer.component.scss']
})
export class CorrectAnswerComponent implements OnInit {
  @Input() template = 'default';
  @Input() answerlist = [];
  @Input() fullwidth = false;
  constructor() { }

  ngOnInit(): void {
  }

}
