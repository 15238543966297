<div class="col-lg-12 p-0">
  <nav class="nav-bar d-flex w-100">
    <img src="./assets/images/logo.png" alt="logo" id="board-logo" width="150px" />
    <ul class="board-btn-blk">
      <li class="nav-item px-5 my-auto ml-auto">
        <a class="btn-home btn"  id="registerbtn" (click)="signout()">Sign out</a>
      </li>
    </ul>
  </nav>
  <section class="main flex-column">
    <div class="container my-auto">
      <div class="col-lg-12 text-center">
        <h1 class="text-white">
          Master Your PTE Exam Preparation with Sydney's Leading Mock Test Platform!<br />
        </h1>
        <p class="text-white">
          JMSPTE gives you the BEST PTE COACHING through passionate and dedicated experts who do everything possible to ensure You GET YOUR 7, 8 OR 9 BAND in Your Very First Attempt with us. We GUARANTEE 9 BAND for 6 question types, if you FOLLOW OUR STRATEGIES EXACTLY AS IT IS.
        </p>
        <div class="col-lg-6 text-center mx-auto">
          <button class="btn-home btn mx-3">FAQ</button>
          <button class="btn-home btn mx-3">TERMS & CONDITIONS</button>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="col-lg-12 py-4">
     <ul class="sample-box">
       <li (click)="redirect(sample)" class="text-center {{ sample?.disabled ? 'disabled' : ''}}" *ngFor="let sample of samples;let i=index">
         <p class="sample-curve-box mb-3 {{ sample?.disabled ? 'disabled' : ''}}">
          <img width="55px" [src]="sample?.icon" alt="icon-{{i}}" [id]="sample.id">
         </p>
         <p class="sample-box-title">
           {{sample?.name}}
          </p>
          <p class="sample-box-content">
           {{sample?.content}}
         </p>
       </li>
     </ul>
    </div>
    <div class="col-lg-12 px-lg-5 py-4">
      <div class="col-lg-12 text-center">
        <h1 class="home-intro-content-title">
          ABOUT PTE
        </h1>
        <p>Australias No.1 PTE training Institute</p>
      </div>
      <div class="col-lg-12 px-lg-5 pxc-4 px-3 py-lg-5">
        <div class="row px-lg-4">
          <div class="col-lg-6 mb-3 {{(i==0)? 'pr-lg-4':'pl-lg-4'}}" *ngFor="let card of homecardData;let i=index">
            <div class="card-blk px-4 py-4" [style.backgroundImage]="'url('+ card?.bgImg + ')'">
              <h4>{{card?.title}}</h4>
              <p class="pt-2 pb-2" [innerHTML]="card?.content|titlecase"></p>
              <button class="btn btn-more">{{card?.btnTitle}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
