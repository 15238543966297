<div class="p-0 dashboard-tile" [ngClass]="{'f-width': !autowidth, 'active': data.id == active}"
  [style.background-color]="data?.color" (click)="action.emit(data)">
  <div class="tile-blk">
    <div class="icon-holder">
      <span>
        <img [src]="data?.icon" id="icon{{data?.id}}" alt="icon-1" width="100%">
      </span>
    </div>
    <div class="label-holder my-auto">
      <p class="title" id="titleCount{{data?.id}}">
        {{data?.title}}
      </p>
    </div>
  </div>
</div>
