import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../helper/helper';
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  providers: [StorageHelper]
})
export class IntroComponent implements OnInit {
  @Input() data;
  @Output() start = new EventEmitter();
  @Output() back = new EventEmitter();
  constructor(public storageHelper: StorageHelper, public router: Router) { }

  ngOnInit(): void {
  }

}
