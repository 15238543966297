import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  @Input() autowidth = false;
  @Input() active;
  @Input() data;
  @Input() count;
  @Output() action = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
