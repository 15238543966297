import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./../login/login.component.scss'],
})
export class VerifyComponent implements OnInit {
  onbardForm: FormGroup;
  enableModal;
  @Input() loader;
  @Output() signIn = new EventEmitter();
  @Output() verifyCode = new EventEmitter();
  constructor(
    public fb: FormBuilder, 
    private activatedRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.onbardForm = this.fb.group({
      verifyEmail: this.fb.group({
        code: ['', [Validators.required]]
      })
    });
    this.enableModal = (window.screen.width < 991) ? true : false;
  }

  get oFormVerifyEmail() {
    return this.onbardForm.get('verifyEmail')['controls'];
  }

  verifyEmail() {
    // verify the data
    this.onbardForm.get('verifyEmail').markAllAsTouched();

    if (this.onbardForm.get('verifyEmail').valid) {
      // get payload
      const payload = this.onbardForm.value.verifyEmail;

      this.verifyCode.emit(payload.code);

      this.signIn.emit({ to: 'login' });
    }
  }
}