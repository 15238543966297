import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as data from '../home/home.data';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  homecardData = data.homeData;
  samples = [
    {
      name:'FREE SAMPLES',
      content:`Get instant access to our online
      sample questions to test your
      skills`,
      icon : './assets/icons/free-sample.svg',
      id:'sample-board-0',
      disabled:false,
    },
    {
      name:'ONLINE SCORE GIVEN TESTS',
      content:`Online score Test which is a
      mock test to prepare for the
      real one`,
      icon : './assets/icons/test-sample.svg',
      id:'sample-board-1',
      disabled:true,
    },
    {
      name:'TEST YOUR COMPUTER',
      content:`Get the best immediate
      preparation for pte
      academic.`,
      icon : './assets/icons/test-computer.svg',
      id:'sample-board-2',
      disabled:true,
    },
    {
      name:'SCORE REPORT',
      content:`Score reports are
      available via your online
      account.`,
      icon : './assets/icons/sample-report.svg',
      id:'sample-board-3',
      disabled:true,
    },
    {
      name:'TESTBANKS',
      content:`AIL PTE Academic
      preparation course
      students ONLY!`,
      icon : './assets/icons/test-banks.svg',
      id:'sample-board-4',
      disabled:true
    }
  ]
  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  signout() {
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  redirect(item){
    if(item['name'] == 'FREE SAMPLES') {
      this.router.navigate(['/dashboard']);
    }
  }
}
