import * as CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';
import { Injectable } from '@angular/core';
const SECRET_KEY = 'JSMPTEApp';

@Injectable({ providedIn: 'root' })
export class StorageHelper {
  constructor() {
  }

  secureStorage(type, key, value?) {
    const secureStorage = new SecureStorage(localStorage, {
      hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
      },
      encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
      },
      decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      }
    });

    if (type === 'set' && key) {
      return secureStorage.setItem(key, value);
    }
    if (type === 'get' && key) {
      return secureStorage.getItem(key);
    }
    if (type === 'remove' && key) {
      return secureStorage.removeItem(key);
    }
    return null;
  }
}

